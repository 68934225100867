import styled from "styled-components";
import { media } from "../../../utils/use-media-query";

const PersonRoot = styled.div<{ $isMobileRoot?: boolean }>`
  display: flex;
  max-width: 144rem;
  gap: 3.2rem;
  margin: ${(props: any) => (props.$isMobileRoot ? "0rem auto" : "9.5rem auto")};
  flex-direction: column;
  padding-bottom: 0rem;
  margin-bottom: 2rem;

  @media (${media.lg}) {
    margin: 0 15rem;
  }

  @media (${media.md}) {
    margin: 0 10rem;
  }

  @media (${media.sm}) {
    margin: 0 2rem;
  }
`;

const PersonWrapper = styled.div<{ $isMobileWrapper?: boolean }>`
  align-self: ${(props: any) => (props.$isMobileWrapper ? "start" : "center")};
  width: ${(props: any) => (props.$isMobileWrapper ? "100%" : "50%")};
  margin: 0;
  padding: 0;
`;

const PersonImageWrapper = styled.div<{ $isMobileWrapper?: boolean }>`
  width: ${(props: any) => (props.$isMobileWrapper ? "100%" : "50%")};
  text-align: -webkit-center;
`;

const PersonHeader = styled.div<{ $flexDirection?: string; $isMobileHeader?: boolean }>`
  display: flex;
  max-width: 144rem;
  width: 100%;
  margin: 0;
  margin-bottom: ${(props: any) => (props.$isMobileHeader ? "1rem" : "9.6rem")};
  margin: ${(props: any) => (props.$isMobileHeader ? "3rem auto 0 auto" : "0 auto")};
  flex-direction: ${(props: any) => props.$flexDirection};

  @media (${media.lg}) {
    padding: 0 15rem;
  }

  @media (${media.md}) {
    padding: 0 10rem;
  }

  @media (${media.sm}) {
    padding: 0 2rem;
  }
`;

const PersonTitle = styled.h2<{ $isMobileText?: boolean }>`
  font-family: Open Sans;
  font-size: ${(props: any) => (props.$isMobileText ? "2rem" : "5.1rem")};
  font-weight: 700;
  line-height: ${(props: any) => (props.$isMobileText ? "3rem" : "4.9rem")};
  margin-top: ${(props: any) => (props.$isMobileText ? "1.8rem" : "0px")};
  letter-spacing: 0em;
  text-align: left;
  color: white;
`;

const PersonSubtitle = styled.h3<{ $isMobileText?: boolean }>`
  font-family: Open Sans;
  font-size: ${(props: any) => (props.$isMobileText ? "1.2rem" : "2.4rem")};
  font-weight: 400;
  line-height: ${(props: any) => (props.$isMobileText ? "3rem" : "4.9rem")};
  letter-spacing: 0em;
  text-align: left;
  color: white;
`;

const PersonDescription = styled.h5<{ $isMobileText?: boolean }>`
  color: #a1a1a1;
  font-family: Open Sans;
  font-size: ${(props: any) => (props.$isMobileText ? "2rem" : "2rem")};
  line-height: ${(props: any) => (props.$isMobileText ? "2.8rem" : "2.8rem")};
  font-weight: 500;
  letter-spacing: 0em;
  width: 100%;
  text-align: justify;
  :first-child {
    margin-top: ${(props: any) => (props.$isMobileText ? "2rem" : "3rem")};
  }
`;

const PersonParagraph = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #d4d4d4;
`;

const PersonDivider = styled.div`
  margin: 4rem auto;
  max-width: 144rem;
  background: #4d4d4d;
  height: 1px;
  width: 100%;
`;

export const Person = {
  Root: PersonRoot,
  Wrapper: PersonWrapper,
  Title: PersonTitle,
  Subtitle: PersonSubtitle,
  Description: PersonDescription,
  Paragraph: PersonParagraph,
  Header: PersonHeader,
  ImageWrapper: PersonImageWrapper,
  Divider: PersonDivider,
};
