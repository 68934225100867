import axiosMain from "axios";

export const baseURL = process.env.REACT_APP_API_URL;
export const userName = process.env.REACT_APP_API_USER_NAME;
export const password = process.env.REACT_APP_API_PASSWORD;

export const axios = axiosMain.create({
  baseURL,
  headers: { userName, password },
});

export const endpoints = {
  languageList: "/languageList/",
  websiteText: "/websiteText/",
  countryOfResidence: "/countryOfResidence/",
  mediaCenterInvestorNews: "/mediaCenterInvestorNews/",
  mediaCenterNews: "/mediaCenterNews/",
  careerDropDown: "/careerDropDown/",
  careerJobs: "/careerJobs/",
  careerJobDtls: "/careerJobDtls/",
  aboutUsLeadership: "/getAllLeadershipData/",
  aboutUsLeadershipPerson: "/getLeadershipData/",
  aboutUsFinanStats: "/aboutUsFinanStats/",
  aboutUsFsShLogin: "/aboutUsFsShLogin/ ",
  aboutUsFinanDropDown: "/aboutUsFinanDropDown/",
  contactBlackBanxEmail: "/contactBlackBanxEmail/",
  globalOffices: "/globalOffices/",
  globalOfficeDropDown: "/globalOfficeDropDown/",
  scripts: "/metaScriptDatas",
  cardBenefitsData: "/getAcCardBenefitData",
};
