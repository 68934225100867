import { axios, endpoints } from "../base.api.cfg";
import { AxiosPromise } from "axios";
import {
  ResLanguagesList,
  ResGetSiteTextData,
  WebsiteTextGroups,
  ResSiteContentMediaCenterInvestorNews,
  ResSiteContentMediaCenterNews,
  ResCareerDropdownOptions,
  ResAboutUsFinanStats,
  ResAboutUsFsShLogin,
  ResAboutUsFinanDropDown,
  ResCareerJobs,
  PostSentCompanyEmail,
  ResAllLeadershipData,
  ResLeadershipPerson,
  ResCardBenefitData,
} from "../types/fetch.ui.types";
import FD from "../../utils/object-to-form-data";

export const fetchLanguageList = (): AxiosPromise<ResLanguagesList> => axios.post(endpoints.languageList);

export const fetchGlobalOffices = (credentials: {
  languageId: string;
  countryCode: string;
}): AxiosPromise<ResCareerDropdownOptions> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.globalOffices,
    data,
  });
};

export const fetchCareerDropDown = (credentials: { languageId: string }): AxiosPromise<ResCareerDropdownOptions> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.careerDropDown,
    data,
  });
};
export const fetchAllLeadershipData = (credentials: { languageId: string }): AxiosPromise<ResAllLeadershipData> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.aboutUsLeadership,
    data,
  });
};
export const fetchLeadershipPersonData = (credentials: {
  languageId: string;
  linkId: string;
}): AxiosPromise<ResLeadershipPerson> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.aboutUsLeadershipPerson,
    data,
  });
};

export const fetchCountryOfResidence = (credentials: { languageId: string }): AxiosPromise<ResLanguagesList> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.countryOfResidence,
    data,
  });
};

export const fetchGlobalOfficeDropDown = (credentials: { languageId: string }): AxiosPromise<ResLanguagesList> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.globalOfficeDropDown,
    data,
  });
};

export const fetchScripts = (): AxiosPromise<ResLanguagesList> => axios.post(endpoints.scripts);

export const fetchCareerJobs = (credentials?: {
  languageId?: string;
  jobLocation?: number;
  postedDays?: number;
  keywords?: string;
  jobKeywordMatch?: number;
}): AxiosPromise<ResCareerJobs> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.careerJobs,
    data,
  });
};

export const fetchCareerJobDtls = (credentials: { languageId: string; jobId: string }): AxiosPromise<any> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.careerJobDtls,
    data,
  });
};
export const fetchMediaCenterInvestorNews = (credentials: {
  languageId: string;
  mcId?: string;
}): AxiosPromise<ResSiteContentMediaCenterInvestorNews> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.mediaCenterInvestorNews,
    data,
  });
};

export const fetchMediaCenterNews = (credentials: {
  languageId: any;
  mnid?: any;
}): AxiosPromise<ResSiteContentMediaCenterNews> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.mediaCenterNews,
    data,
  });
};

export const fetchWebsiteText = (credentials: {
  pageId: string;
  languageId: string;
}): AxiosPromise<ResGetSiteTextData> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.websiteText,
    data,
  });
};

export const fetchAboutUsFinanStats = (credentials: {
  languageId: string;
  finDataId?: string;
}): AxiosPromise<ResAboutUsFinanStats> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.aboutUsFinanStats,
    data,
  });
};
export const fetchAboutUsFsShLogin = (credentials: {
  languageId: string;
  finDataId?: string;
  userName: any;
  password: any;
  doc_type: string;
  filing: any;
}): AxiosPromise<ResAboutUsFsShLogin> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.aboutUsFsShLogin,
    data,
  });
};
export const fetchAboutUsFinanDropDown = (credentials: {
  languageId: string;
}): AxiosPromise<ResAboutUsFinanDropDown> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.aboutUsFinanDropDown,
    data,
  });
};

export const fetchSendCompanyMessage = (credentials: {
  languageId: string;
  destination: string;
  subject: string;
  mailContent: string;
}): AxiosPromise<PostSentCompanyEmail> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.contactBlackBanxEmail,
    data,
  });
};

export const fetchCardBenefitData = (credentials: {
  languageId: string;
}): AxiosPromise<ResCardBenefitData> => {
  const data = new FD(credentials).get();
  return axios({
    method: "POST",
    url: endpoints.cardBenefitsData,
    data,
  });
};

export const fetchWebSiteTextAll = async (languageId: string) => {
  const namesMap = new Map();
  namesMap.set(1, "common");
  namesMap.set(2, "homePage");
  namesMap.set(3, "aboutUsMission");
  namesMap.set(4, "aboutUsSecurity");
  namesMap.set(5, "aboutUsCareers");
  namesMap.set(6, "accountsBankAccounts");
  namesMap.set(7, "accountsCryptoCurrency");
  namesMap.set(8, "accountsFees");
  namesMap.set(9, "mediaCenterMediaCoverage");
  namesMap.set(10, "mediaCenterNews");
  namesMap.set(11, "mediaCenterBlog");
  namesMap.set(12, "contactBlackBanx");
  namesMap.set(13, "policiesTerms");
  namesMap.set(14, "privacyPolicy");
  namesMap.set(15, "codeOfConduct");
  namesMap.set(16, "culture");
  namesMap.set(17, "governance");
  namesMap.set(18, "leadership");
  namesMap.set(19, "riskManagement");
  namesMap.set(20, "sustainabilityCareers");
  namesMap.set(21, "financialStatements");
  namesMap.set(22, "mediaCenterInvestorNews");
  namesMap.set(23, "complaintsPolicy");
  namesMap.set(24, "slaveryPolicy");

  const max = 24;
  const requests: {
    tab: WebsiteTextGroups;
    data: ResGetSiteTextData;
    meta: string;
  }[] = [];
  for (let i = 1; i <= max; i++) {
    const tab = namesMap.get(i);
    const dummyObj: Record<string, string> = {};

    const values = await fetchWebsiteText({ pageId: i.toString(), languageId });
    values.data.data.forEach((el) => {
      const [obj] = Object.entries(el);
      const [key, value] = obj;
      dummyObj[key] = value;
    });
    const meta = values.data.metaData;
    //@ts-ignore
    requests.push({ tab, data: dummyObj, meta });
  }

  return await Promise.all(requests);
};
