import styled from "styled-components";
import { media } from "../../utils/use-media-query";

export const CareersWorkforceDiv = styled.div<{ border: boolean }>`
  display: flex;
  align-items: center;
  padding: 40px 0px 40px 60px;
  border-bottom: ${(props) => (props.border ? "1px solid grey" : "")};
  @media (${media.sm}) {
     {
      padding: 10px 0px 10px 0px;
      line-height: 1.5;
    }
  }
`;
export const CareersWorkforceImgDiv = styled.div`
  width: 130px;
  height: 130px;
  @media (${media.sm}) {
     {
      width: 100px;
      height: 100px;
    }
  }
`;
export const CareersWorkforceImg = styled.img`
  width: 130px;
  height: 130px;
  @media (${media.sm}) {
     {
      width: 100px;
      height: 100px;
    }
  }
`;

export const CareersWorkforceText = styled.p`
  font-size: 20px;
  margin-left:50px;
  text-align: justify;
  @media (${media.sm}) {
     {
      font-size: 15px;
      margin-left:20px;
    }
  }
`;

export const CareersWorkforceBlock = styled.div`
  margin-bottom: 80px;
  @media (max-width: 1400px) {
    margin: 0px auto 80px auto;
  }
`;
export const CareersWorkforceBlockDiv = styled.div`
  display: flex;
  @media (max-width: 1400px) {
    flex-direction: column;
  }
`;
export const CareersWorkforceBlockMainDiv = styled.div<{
  space: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 30px;
  border-right: ${(props) => (props.borderRight ? "1px solid grey" : "")};
  border-bottom: ${(props) => (props.borderBottom ? "1px solid grey" : "")};
  @media (${media.sm}) {
    border-bottom: ${(props) => (props.borderBottom ? "1px solid grey" : "")};

    border-right: none;
    padding: 25px 0px 25px 0px;
  }
`;
export const CareersWorkforceBlockImg = styled.img`
  width: 120px;
  height: 120px;
  margin-right: 20px;
  margin-bottom: 20px;
  @media (max-width: 1400px) {
    margin: 0px 30px 0px 0px;
  }
`;
export const CareersWorkforceBlockHeading = styled.h3`
  margin-bottom: 5px;
  font-size: 28px;
  @media (${media.sm}) {
     {
      font-size: 15px;
    }
  }
`;

export const CareersWorkforceBlockText = styled.p`
  font-size: 20px;
  @media (${media.sm}) {
     {
      font-size: 15px;
      line-height: 1.5;
    }
  }
`;

export const ResponseMessage = styled.div`
  font-size: 2.4rem;
  line-height: 3.6rem;
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
`;
