export const uiDataLanguageList = (state) => state.uiDataSlice.languageList;
export const uiDataGlobalOffices = (state) => state.uiDataSlice.globalOffices;
export const uiDataSelectedLanguageId = (state) => state.uiDataSlice.selectedLanguageId;
export const uiDataWebsiteText = (state) => state.uiDataSlice.websiteText;
export const uiDataScripts = (state) => state.uiDataSlice.scripts;
export const uiDataFetching = (state) => state.uiDataSlice.loading;
export const uiDataCountryOfResidence = (state) => state.uiDataSlice.countryOfResidence;
export const uiDataGlobalOfficeDropDown = (state) => state.uiDataSlice.globalOfficeDropDown;

export const uiDataMediaCenterNews = (state) => state.uiDataSlice.mediaCenterNewsData;
export const uiDataMediaCenterBlog = (state) => state.uiDataSlice.mediaCenterBlogData;
export const uiMediaCenterInvestorNews = (state) => state.uiDataSlice.mediaCenterInvestorNewsData;
export const uiCareerDropDown = (state) => state.uiDataSlice.careerDropDown;
export const uiAboutUsLeadership = (state) => state.uiDataSlice.aboutUsLeadership;
export const uiAboutUsLeadershipPerson = (state) => state.uiDataSlice.aboutUsLeadershipPerson;
export const uiAboutUsFinanStats = (state) => state.uiDataSlice.aboutUsFinanStats;
export const uiAboutUsFinanDropDown = (state) => state.uiDataSlice.aboutUsFinanDropDown;
export const uiCareerJobs = (state) => state.uiDataSlice.careerJobs;
export const uiCardBenefits = (state) => state.uiDataSlice.cardBenefits;
export const getSelectedLanguage = (state) => state.uiDataSlice.selectedLanguageId;

const uiDataSelectors = {
  uiDataLanguageList,
  uiDataGlobalOffices,
  uiDataWebsiteText,
  uiDataScripts,
  uiDataSelectedLanguageId,
  uiDataCountryOfResidence,
  uiDataMediaCenterNews,
  uiDataMediaCenterBlog,
  uiMediaCenterInvestorNews,
  uiCareerDropDown,
  uiAboutUsLeadership,
  uiAboutUsLeadershipPerson,
  uiAboutUsFinanStats,
  uiAboutUsFinanDropDown,
  uiCareerJobs,
  uiCardBenefits,
  getSelectedLanguage,
};
export default uiDataSelectors;
