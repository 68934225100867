import React, { useEffect } from "react";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import MainBg from "../../assets/backgrounds/leadership/leadership_main_bg.png";
import { Section } from "../../components/section/section.component";
import { useMediaQuery } from "../../utils/use-media-query";
import { uiAboutUsLeadership, uiDataSelectedLanguageId, uiDataWebsiteText } from "../../redux/uiData/selectors";
import { useDispatch, useSelector } from "../../redux/store";
import parse from "html-react-parser";

import { Leadership } from "./leadership.styles";

import { Helmet } from "react-helmet";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";
import operations from "../../redux/uiData/operations";
import { Base64 } from "js-base64";
import { LeaderShipDirectorItem, LeaderShipExecutiveItem, LeaderShipProfileType } from "../../api/types/fetch.ui.types";

export const LeadershipPage: React.FC = (): JSX.Element => {
  const language = useSelector(uiDataSelectedLanguageId);
  const data = useSelector(uiAboutUsLeadership);
  const { leadership } = useSelector(uiDataWebsiteText);
  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery("md");

  return (
    <>
      <HelmetContainer metaData={leadership.metaData} />
      <SlideSection
        bgImage={MainBg}
        title={parse(leadership.abtus_ldrshp_mainhead_nxt + " <br> " + leadership.abtus_ldrshp_mainhead)}
        bgVariant={"gradient"}
        titleAlign={"center"}
        justify={"center"}
        titleWidth={"100%"}
        BgImageStyles={isMobile ? { backgroundSize: "cover", backgroundPosition: "40%" } : { backgroundSize: "cover" }}
      />

      <Leadership.Content $isMobile={isMobile}>
        <Leadership.Header text-align="center">Group Senior Executives</Leadership.Header>
        <Leadership.CardGridContainer>
          <>
            {data?.executive_prfle_data?.map((item: LeaderShipExecutiveItem) => {
              return (
                <Leadership.CardContainer
                  $isMobile={isMobile}
                  key={item?.abtus_ldr_prfle_link}
                  onClick={() => `${window.open("/leadership" + item?.abtus_ldr_prfle_link, "_self")}`}
                >
                  <Leadership.CardImage
                    className="leadership_image"
                    src={`assets/images/leadership/${item?.abtus_ldr_prfle_image}`}
                  />

                  <Leadership.CardName className="leadership_name">{item?.abtus_ldr_prfle_name}</Leadership.CardName>
                  <Leadership.CardTitle className="leadership_title">
                    <div dangerouslySetInnerHTML={{ __html: item?.abtus_ldr_prfle_position }} />
                  </Leadership.CardTitle>
                </Leadership.CardContainer>
              );
            })}
          </>
        </Leadership.CardGridContainer>

        <Leadership.Divider style={{ marginTop: "6rem" }} />

        <Leadership.BodHeader $isMobile={isMobile}>{leadership?.abtus_ldr_dir_title}</Leadership.BodHeader>
        <Leadership.DirectorsCardGridContainer>
          {data?.director_prfle_data?.map((item: LeaderShipDirectorItem) => {
            const type: LeaderShipProfileType = item?.abtus_ldr_prfle_type;

            return (
              <Leadership.DirectorCardContainer
                $type={type}
                $isMobile={isMobile}
                onClick={() =>
                  type === "1"
                    ? `${window.open("/leadership" + item?.abtus_ldr_prfle_link, "_self")}`
                    : type === "2"
                      ? `${window.open(item?.abtus_ldr_prfle_link, "_blank")}`
                      : null
                }
              >
                <Leadership.DirectorCardName>{item.abtus_ldr_prfle_name}</Leadership.DirectorCardName>
                <Leadership.DirectorCardTitle>
                  <div dangerouslySetInnerHTML={{ __html: item?.abtus_ldr_prfle_position }} />
                </Leadership.DirectorCardTitle>
              </Leadership.DirectorCardContainer>
            );
          })}
        </Leadership.DirectorsCardGridContainer>

        <Leadership.Divider />
      </Leadership.Content>
    </>
  );
};
