import React, { PropsWithChildren } from "react";
import { Wrap } from "../wrap/wrap.component";
import { Section } from "../section/section.component";
import { MainHeader } from "../../pages/main/main.styles";
import { ButtonArrow } from "../button-arrow/button-arrow.component";
import { BgGradient } from "../bg-gradient/bg-gradient.component";
import { SlideSectionProps } from "./slide.section.types";
import { useMediaQuery } from "../../utils/use-media-query";
import { relative } from "path";

export const SlideSection: React.FC<PropsWithChildren<SlideSectionProps>> = (props) => {
  const {
    children,
    bgImage,
    title,
    justify,
    button,
    bgVariant,
    titleWidth,
    titleAlign,
    m,
    h,
    mobile,
    SectionStyles = {},
    BgImageStyles,
    TitleSectionStyles = {},
    titleStyles = {},
    buttonArrowSectionStyles = {},
    positionAbsolute,
    customHeaderComponent,
  } = props;
  const isMobile = useMediaQuery("md");

  return (
    <>
      <Section align={"center"} m={m} style={SectionStyles}>
        {title && isMobile && (
          <Wrap
            sx={
              bgVariant !== "gradient"
                ? {
                    maxWidth: titleWidth || "100%",
                    width: "100%",
                    textTransform: "uppercase",
                    display: "flow-root",
                    textAlign: titleAlign || "start",
                    padding: "0 1em",
                    ...TitleSectionStyles,
                  }
                : {
                    maxWidth: "100%",
                    display: "flex",
                    textAlign: titleAlign || "start",

                    textTransform: "uppercase",
                    position: "absolute",
                    height: "100%",
                    zIndex: "2",
                    ...TitleSectionStyles,
                  }
            }
          >
            <MainHeader titleAlign={titleAlign} style={titleStyles}>{title}</MainHeader>
          </Wrap>
        )}
        {/* {title && isMobile && bgVariant === "gradient" && (
          <Wrap
            sx={{
              maxWidth: "100%",
              display: "flex",
              textTransform: "uppercase",
              position: positionAbsolute? "initial" :"absolute",
              height: "100%",
              zIndex: "2",
            }}
          >
            <MainHeader>{title}</MainHeader>
          </Wrap>
        )} */}
        {bgVariant === "gradient" ? (
          <BgGradient isMobile={isMobile} imgSrc={bgImage} sx={BgImageStyles} h={h} />
        ) : (
          customHeaderComponent || (
            <Wrap
              sx={{
                position: "relative",
                background: `url(${bgImage})`,
                height: mobile ? "34rem" : "65rem",
                width: "100%",
                maxWidth:"1880px",
                margin:"0 auto",
                backgroundRepeat: "no-repeat",
                ...BgImageStyles,
              }}
            />
          )
        )}

        <Section
          mainContent
          justify={"center"}
          align={justify === "center" ? "center" : justify === "right" ? "flex-end" : "flex-start"}
          pos={mobile ? "relative" : "absolute"}
          h={"100%"}
          style={TitleSectionStyles}
        >
          {title && !isMobile && (
            <Wrap
              sx={{
                maxWidth: titleWidth || "100%",
                width: "100%",
                textTransform: "uppercase",
                display: "flow-root",
                textAlign: titleAlign || "start",
              }}
            >
              <MainHeader titleAlign={titleAlign || 'left'} style={titleStyles}>{title}</MainHeader>
            </Wrap>
          )}
          {!isMobile && button && (
            <Wrap
              sx={{
                position: "relative",
                top: "4.4rem",
                alignSelf: isMobile ? "center" : "flex-start",
                ...buttonArrowSectionStyles,
              }}
            >
              <ButtonArrow onClick={button.onClick}>{button.title}</ButtonArrow>
            </Wrap>
          )}
        </Section>
        {isMobile && button && (
          <Wrap
            sx={{
              position: "relative",
              ...buttonArrowSectionStyles,
            }}
          >
            <ButtonArrow onClick={button.onClick}>{button.title}</ButtonArrow>
          </Wrap>
        )}
      </Section>
      {children}
    </>
  );
};
