import styled from "styled-components";
import { media } from "../../../utils/use-media-query";

export const TextMainContainer = styled.div`
  color: white;
`;
export const TextMainTitle = styled.h3`
  line-height: 3.8rem;
  text-align: center;
  margin-bottom: 40px;
  @media (${media.sm}) {
    font-size: 3rem;
  }
`;
// export const TextTitle = styled.h3`
//   margin-top: 20rem;
//   margin-bottom: 4rem;
//   line-height: 2.4rem;
//   font-size: 3.4rem;
//   text-transform: uppercase;
//       font-size: 2rem;
//       text-transform: uppercase;
//       font-weight: 700;

//     }
//   }
// `;
export const TextMiniTitle = styled.h5`
  margin-top: 2rem;
  line-height: 1.4em;
  font-size: 2.4rem;
  font-weight: 600;
  
  @media (${media.sm}) {
     {
      font-size: 2rem;
      line-height: 1.5;
      font-weight: 400;
      margin-bottom: 20px;
      text-align: center;
    }
  }
`;
export const TextMiniTitleLeft = styled.h5`
  margin-top: 2rem;
  text-align: left;
  line-height: 1.5em;
  font-size: 2.2rem;
  font-weight: 500;
  width: 100%;

  @media (${media.sm}) {
     {
      font-size: 2rem;
      line-height: 1.5;
      text-align: center;

      font-weight: 400;
      margin-bottom: 20px;
    }
  }
`;
// export const TextMiniTitle = styled.h5`
//   line-height: 1.4em;
//   font-size: 2.2rem;
//   font-weight: 600;
//   text-align: left;

//   @media (${media.sm}) {
//      {
//       font-size: 20px;
//       line-height: 1.5;
//       margin-bottom: 2.5rem;
//       margin-bottom: 2.5rem;
//       font-weight: 600;
//       text-align: center;
//     }
//   }
// `;
export const TextHead = styled.h2`

  @media (${media.sm}) {
     {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      text-align: center;
    }
  }
`;

export const TextTitle = styled.h2`
  margin-top: 4rem;
  margin-bottom: 4rem;
  line-height: 3.8rem;
  font-size: 40px;
  text-align: center;
  align-self: center;
  width: 100%;
  @media (${media.sm}) {
     {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 2.5rem;
    }
  }
`;
export const TextTitleLeft = styled.h3`
  margin-top: 4rem;

  font-weight: 600;
  margin-bottom: 4rem;
  line-height: 2.4rem;
  font-size: 3rem;
  text-align: left;
  @media (${media.sm}) {
     {
      font-size: 2.5rem;
      line-height: 1.5;
      margin-bottom: 2.5rem;
      text-align: center;
    }
  }
`;

// export const TextSubTitle = styled.p<{ italic?: boolean }>`
//   font-size: 1.8rem;
//   font-weight: bold;
//   margin-top: 5rem;
//   padding-bottom: 5px;
//       margin-bottom: 2rem;
//       font-weight: 400;
//     }
//   }
// `;
export const TextSubTitle = styled.p<{ italic?: boolean; center?: boolean }>`
  font-size: 2.2rem;
  /* font-weight: bold; */
  margin-top: 1.4rem;
  /* margin-right: 1rem; */
  color: white;
  @media (${media.sm}) {
     {
      font-size: 1.8rem;
      line-height: 1.5;
      align-self: center;
      text-align: center;
      letter-spacing: 0.39px;
      margin-top: 3rem;
    }
  }
  ${(props) =>
    props.italic &&
    `
    font-style: italic;
  `}
`;
export const TextDescription = styled.div<{ italic?: boolean; margin?: boolean }>`
  font-size: 1.8rem;
  line-height: 32px;
  text-align: justify;
  color: #a1a1a1;
  font-weight: 300;

  ${(props) =>
    props.italic === true &&
    `
    font-style: italic;
  `}
  @media (${media.sm}) {
     {
      line-height: 16.34px;
      font-size: 12px;
    }
  }
`;
export const SpecialQuoteMessageWrapper = styled.div`
  position: relative;
  border: 1px solid #54595d;
  border-radius: 1.5rem;
  padding: 2.5rem;
  margin: 6rem 45rem;
  @media (max-width: 1440px) {
    margin: 6rem 32rem;
  }
  @media (max-width: 1024px) {
    margin: 6rem 16rem;
  }
  @media (max-width: 430px) {
    margin: 6rem 0rem;
  }
  @media (max-width: 375px) {
    padding: 2rem;
  }
  @media (max-width: 320px) {
    padding: 1rem 0rem;
  }
`;
export const SpecialQuoteMessage = styled.p`
  text-align: center;
  font-style: italic;
  color: #979797;
  font-size: 2rem;
  @media (max-width: 992px) {
    font-size: 1.6rem;
  }
  @media (max-width: 375px) {
    font-size: 1.4rem;
  }
  @media (max-width: 320px) {
    font-size: 1.3rem;
  }
`;
export const SpecialQuoteComa = styled.img`
  position: absolute;
  width: 5rem;
  height: 5rem;
  top: -3rem;
  left: 2.5rem;
  @media (max-width: 425px) {
    width: 3.5rem;
  }
`;
export const SpecialQuoteComaReverse = styled.img`
  position: absolute;
  width: 5rem;
  height: 5rem;
  right: 2.5rem;
  bottom: -3rem;
  @media (max-width: 425px) {
    width: 3.5rem;
  }
`;