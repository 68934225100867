import styled from "styled-components";
import { media } from "../../utils/use-media-query";

export const RManagementTitle = styled.h1`
    color :white;
    font-size: 40px;
    font-weight : 600;
    margin-bottom: 64px;
    @media (${media.sm}) {
     {
        font-size: 2.5rem;
        line-height: 3.5rem;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    }
  }
`;

export const RManagementHeading = styled.p`
    color:white;
    font-size: 16px;
    font-weight: 500;
    
    @media (${media.sm}) {
     {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
        padding-bottom: 10px;
        text-align: left;
    }
  }
`
export const RManagementContent = styled.p`
    color : #A1A1A1;
    margin-bottom : 4rem;
    font-weight: 300;
    
    @media (${media.sm}) {
     {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-align: left;

    }
  }
`;

export const RManagementList = styled.ul`
    margin-bottom : 4rem;
`;

export const RManagementListContent = styled.li`
    color : #A1A1A1;
    font-weight: 300;
    
    list-style-type: circle;
`;