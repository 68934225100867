import styled from "styled-components";
import { media } from "../../utils/use-media-query";

export const FinancialStatementMapItems = styled.div`
  width: 100%;
  overflow-y: scroll;
  height: 260px;

  @media (${media.sm}) {
    margin-top: 5rem;
    height: 812px;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`;
export const FinancialStatementText = styled.p`
  font-size: 22px;
  @media (max-width: 992px) {
    font-size: 18px;
  }
`;

export const DropdownDiv = styled.div<{marginRight?: string}>`
  margin-right: ${(props) => props.marginRight || '0px'};
  width: 100%;
  margin-bottom: 154px;

  @media (${media.sm}) {
    margin-right: 0px;
    height: auto;
    margin-bottom: 12px;
  }
`;

export const TableHeader = styled.h2`
  @media (${media.sm}) {
    font-size: 20px;
    text-transform: uppercase;
  }
`;

export const FinancialStatementItem = styled.div`
  width: 100%;
  display: grid;
  fontSize: 22px;
  grid-template-columns: 25% 25% 25% 25%;
  text-align: center;
  border-bottom: 0.1rem solid white;
  border-top: 0.1rem solid white;
  padding: 1rem;

  @media (${media.sm}) {
    grid-template-columns: 50% 50%;
    row-gap: 20px;
    text-align: left;
    border: 1px solid #838383;
    border-radius: 15px;
    margin-bottom: 16px;
    padding: 16px;
  }
`;