import React, { useEffect, useState } from "react";
import { Section } from "../../components/section/section.component";
import { Wrap } from "../../components/wrap/wrap.component";
import BgImage from "../../assets/backgrounds/bank-accounts/city_bg.png";
import {
  DescriptionContainer,
  ProfItemDescription,
  ProfItemTitle,
  Description,
  DescriptionWrapper,
  ProfItemContainer,
  ProfsContainer,
  DescriptionTitle,
  InfoBlockContainer,
  InfoWrapper,
  BlockSubtitle,
  BlockTitle,
  InfoListContainer,
  InfoListItem,
  ImgWrapper,
  CountrySection,
  FindCountry,
} from "./bank-accounts.styles";
import { ReactComponent as BuildingIcon } from "../../assets/icons/crypto-currency/building.svg";
import { ReactComponent as CardIcon } from "../../assets/icons/crypto-currency/card.svg";
import { ReactComponent as CaseIcon } from "../../assets/icons/crypto-currency/case.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_icon.svg";
import { ReactComponent as CheckMark } from "../../assets/icons/tick_light.svg";
import { ReactComponent as CrossMark } from "../../assets/icons/close_icon.svg";
import { ButtonArrow } from "../../components/button-arrow/button-arrow.component";
// import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "../../utils/use-media-query";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import { uiDataCountryOfResidence, uiDataWebsiteText } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";
import { Input } from "../../components/inputs/input/input.component";

export const BankAccountsPage: React.FC = (): JSX.Element => {
  const { accountsBankAccounts } = useSelector(uiDataWebsiteText);
  const [searchValue, setSearchValue] = useState<string>("");
  const [country, setCountry] = useState("");
  const countryy = useSelector(uiDataCountryOfResidence);
  const [countryModal, setCountryModal] = useState<boolean>(false);
  const [countryTxt, setCountryTxt] = useState<string>("");
  const isTablet = useMediaQuery("md");
  // const nav = useNavigate();
  const isMobile = useMediaQuery("sm");

  const countries: { country: string }[] = [
    { country: accountsBankAccounts.ac_fee_lower_drp_dflt_one },
    { country: accountsBankAccounts.ac_fee_lower_drp_dflt_two },
    { country: accountsBankAccounts.ac_fee_lower_drp_dflt_three },
    { country: accountsBankAccounts.ac_fee_lower_drp_dflt_four },
    { country: accountsBankAccounts.ac_fee_lower_drp_dflt_five },
  ];

  useEffect(() => {
    const handleBodyClick = () => {
      setCountry("");
      setSearchValue("");
    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handleLocation = (value: string) => {
    setCountry(value);
  };

  const handleCountryClick = (index: number) => {
    setCountry("");
    if (!countryy[index].isRegisterBlock) {
      setCountryTxt(`Please proceed, we are offering accounts for clients based in ${countryy[index].name}`);
      setCountryModal(true);
    } else {
      setCountryTxt(`Sorry, we can not accept customers from ${countryy[index].name} at the moment.`);
      setCountryModal(true);
    }
  };

  const handleClickButton = (path: string) => () => {
    window.location.href = path;
  };
  const bankAccountProfs: { title: string; description: string }[] = [
    {
      title: accountsBankAccounts.ac_bnk_mainhead_txtone,
      description: accountsBankAccounts.ac_bnk_sectionone_hdonetxt,
    },
    {
      title: accountsBankAccounts.ac_bnk_mainhead_txttwo,
      description: accountsBankAccounts.ac_bnk_sectionone_hdtwotxt,
    },
    {
      title: accountsBankAccounts.ac_bnk_mainhead_txtthree,
      description: accountsBankAccounts.ac_bnk_sectionone_hdthreetxt,
    },
  ];

  const infoBlocks: {
    title: string;
    subtitle: string;
    description: string[] | string;
    blockImgSrc: React.ReactNode;
    link: string;
    buttonCenter: any;
  }[] = [
    {
      title: accountsBankAccounts.ac_bnk_mainhead_txtone,
      subtitle: accountsBankAccounts.ac_bnk_sectiontwo_subhd,
      link: `${accountsBankAccounts.ac_bnk_mainhead_privatereglnk}`,
      description: [
        accountsBankAccounts.ac_bnk_sectiontwo_listleft_one,
        accountsBankAccounts.ac_bnk_sectiontwo_listright_one,
        accountsBankAccounts.ac_bnk_sectiontwo_listleft_two,
        accountsBankAccounts.ac_bnk_sectiontwo_listright_two,
        accountsBankAccounts.ac_bnk_sectiontwo_listleft_three,
        accountsBankAccounts.ac_bnk_sectiontwo_listright_three,
        accountsBankAccounts.ac_bnk_sectiontwo_listleft_four,
        accountsBankAccounts.ac_bnk_sectiontwo_listright_four,
        accountsBankAccounts.ac_bnk_sectiontwo_listleft_five,
      ],
      blockImgSrc: <CardIcon />,
      buttonCenter: false,
    },
    {
      title: accountsBankAccounts.ac_bnk_mainhead_txttwo,
      subtitle: accountsBankAccounts.ac_bnk_sectionthree_subhd,
      link: `${accountsBankAccounts.ac_bnk_mainhead_businessreglnk}`,
      description: [
        accountsBankAccounts.ac_bnk_sectionthree_listleft_one,
        accountsBankAccounts.ac_bnk_sectionthree_listright_one,
        accountsBankAccounts.ac_bnk_sectionthree_listleft_two,
        accountsBankAccounts.ac_bnk_sectionthree_listright_two,
        accountsBankAccounts.ac_bnk_sectionthree_listleft_three,
        accountsBankAccounts.ac_bnk_sectionthree_listright_three,
        accountsBankAccounts.ac_bnk_sectionthree_listleft_four,
        accountsBankAccounts.ac_bnk_sectionthree_listright_four,
        accountsBankAccounts.ac_bnk_sectionthree_listleft_five,
        accountsBankAccounts.ac_bnk_sectionthree_listright_five,
        accountsBankAccounts.ac_bnk_sectionthree_listleft_six,
        accountsBankAccounts.ac_bnk_sectionthree_listright_six,
      ],
      blockImgSrc: <CaseIcon />,
      buttonCenter: false,
    },
    {
      title: accountsBankAccounts.ac_bnk_mainhead_txtthree,
      subtitle: accountsBankAccounts.ac_bnk_sectionfour_subhd,
      link: `${accountsBankAccounts.ac_bnk_mainhead_institutionalreglnk}`,
      description: accountsBankAccounts.ac_bnk_sectionfour_paraone,
      blockImgSrc: <BuildingIcon />,
      buttonCenter: true,
    },
  ];

  const renderResults = () => {
    return (
      <>
        <Wrap
          sx={{
            width: "100%",
            marginBottom: "1rem",
            position: "absolute",
            top: "8rem",
            zIndex: 20,
          }}
        >
          <FindCountry.Result>
            {countryy
              .map((item: any, index: number) => {
                return { ...item, index };
              })
              .filter((item: any, index: any) => item.name?.toLowerCase().includes(country?.toLowerCase()))
              .map((item: any, index: any, arr: any) => {
                return (
                  <>
                    <Wrap
                      onClick={() => {
                        handleCountryClick(item.index);
                      }}
                      sx={{
                        cursor: "pointer",
                        ...(index !== arr.length - 1
                          ? {
                            borderBottom: "1px solid white",
                            marginBottom: "1.5rem",
                            paddingBottom: "10px",
                            cursor: "pointer",
                          }
                          : {}),
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{item.name} </div>
                      {!item.isRegisterBlock ? <CheckMark width={"24px"} /> : <CrossMark width={"24px"} />}
                      {/* <br /> */}
                    </Wrap>
                  </>
                );
              })}
          </FindCountry.Result>
        </Wrap>
      </>
    );
  };

  return (
    <>
      <HelmetContainer metaData={accountsBankAccounts.metaData} />
      <SlideSection
        bgImage={BgImage}
        title={accountsBankAccounts.ac_bnk_mainhead}
        bgVariant={"gradient"}
        titleWidth={"100%"}
        justify={"center"}
        titleAlign={"center"}
        BgImageStyles={
          isMobile
            ? {
                opacity: "1 !important",
                backgroundPosition: "center",
                backgroundSize: "120rem 100%",
                backgroundRepeat: "no-repeat",
              }
            : {
                opacity: "1 !important",
                backgroundSize: "100% 100%",
              }
        }
      />
      <Section mainContent m={isMobile ? "3rem auto": "9rem auto"}>
        <DescriptionWrapper>
          <ProfsContainer>
            {bankAccountProfs.map((prof, idx) => (
              <ProfItemContainer key={`prof-item-${idx}`}>
                <ProfItemTitle>{prof.title}</ProfItemTitle>
                <ProfItemDescription>{prof.description}</ProfItemDescription>
              </ProfItemContainer>
            ))}
          </ProfsContainer>
          <DescriptionContainer>
            <DescriptionTitle>{accountsBankAccounts.ac_bnk_sectionone}</DescriptionTitle>
            <Description>{accountsBankAccounts.ac_bnk_sectiononetxt}</Description>
          </DescriptionContainer>
        </DescriptionWrapper>
      </Section>
      <Section mainContent m={isMobile ? "0 auto" : "11rem auto"}>
        {infoBlocks.map((block, idx) => (
          <>
            <InfoBlockContainer reverse={idx % 2 !== 0} key={`info-block-item-${idx}`}>
            {isMobile &&  <ImgWrapper reverse={idx % 2 === 0}>{block.blockImgSrc}</ImgWrapper> }

              <InfoWrapper>
                <BlockTitle>{block.title}</BlockTitle>
                <BlockSubtitle>{block.subtitle}</BlockSubtitle>
                {typeof block.description === "string" ? (
                  <Wrap sx={{ marginBottom: isMobile ? "5rem" : "0" }}>{block.description}</Wrap>
                ) : (
                  <div style={{ display: "flex", flexDirection:isMobile?"column":"initial", columnGap: "20px" }}>
                    <InfoListContainer>
                      {block.description
                        .filter((item, index) => index % 2 === 0)
                        .map((desc, idx) => (
                          <InfoListItem key={`info-list-item-${idx}`}>{desc}</InfoListItem>
                        ))}
                    </InfoListContainer>
                    {/* <div> */}
                    <InfoListContainer>
                      {block.description
                        .filter((item, index) => index % 2 !== 0)
                        .map((desc, idx) => (
                          <InfoListItem key={`info-list-item-${idx}`}>{desc}</InfoListItem>
                        ))}
                    </InfoListContainer>
                    {/* </div> */}
                  </div>
                )}
                {!isMobile && (
                  <Wrap
                    style={
                      idx % 2 === 0
                        ? {
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "3rem",
                          }
                        : {
                            display: "flex",
                            marginTop: "3rem",
                          }
                    }
                  >
                    <Wrap
                      style={
                        idx % 2 === 0
                          ? {
                              transform: "translateX(40%)",
                            }
                          : {
                              transform: "translateX(-40%)",
                            }
                      }
                    >
                      <ButtonArrow onClick={handleClickButton(block.link)}>
                        {accountsBankAccounts.ac_bnk_mainhead_actionname}
                      </ButtonArrow>
                    </Wrap>
                  </Wrap>
                )}
                <>
                  {/* {!isMobile && (
                  <Wrap
                    style={{
                      margin: block.buttonCenter === true ? "60px auto 0px -7.4rem" : "60px 0px 0px auto",
                    }}
                  >
                    <ButtonArrow onClick={handleClickButton(block.link)}>
                      {accountsBankAccounts.ac_bnk_mainhead_actionname}
                    </ButtonArrow>
                  </Wrap>
                )} */}
                </>
              </InfoWrapper>

            {!isMobile &&  <ImgWrapper reverse={idx % 2 === 0}>{block.blockImgSrc}</ImgWrapper> }
             
              {isMobile && (
                <Wrap sx={{ marginLeft: "auto", marginTop: "3rem", marginRight: "2rem" }}>
                  <ButtonArrow onClick={handleClickButton(block.link)}>
                    {accountsBankAccounts.ac_bnk_mainhead_actionname}
                  </ButtonArrow>
                </Wrap>
              )}
            </InfoBlockContainer>
          </>
        ))}
      </Section>

      <CountrySection mainContent>
        <FindCountry.Root>
          <Wrap
            sx={{
              gridArea: "country",
              display: "flex",
              flexDirection: "column",
              marginBottom: isMobile ? "6rem" : "inherit",
              gap: isTablet ? "2rem" : "0",
            }}
          >
            {countries.map((country, idx) => {
              const { Item } = FindCountry.Countries;
              return <Item key={country.country + idx}>{`${country.country}`}</Item>;
            })}
          </Wrap>
          {isMobile && (
            <>
              <Wrap
                sx={{
                  gridArea: "search",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                  margin: isMobile ? "5rem 0" : "inherit",
                  position: "relative",
                }}
              >
                {country && renderResults()}
                <Input
                  fullWidth
                  value={searchValue}
                  startIcon={<SearchIcon />}
                  placeholder={accountsBankAccounts.ac_fee_typslde_subfoot_searchtxt}
                  onChange={(e) => handleLocation(e.target.value)}
                  helperText={accountsBankAccounts.ac_fee_typslde_subfoot_searchtxthint}
                />
              </Wrap>
            </>
          )}
          <Wrap sx={{ gridArea: "title" }}>
            <FindCountry.Title>{accountsBankAccounts.ac_fee_typslde_subfoot_one}</FindCountry.Title>
          </Wrap>
          {!isMobile && (
            <>
              <Wrap
                sx={{
                  gridArea: "search",
                  display: "grid",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gapY: "2px",
                  position: "relative",
                }}
              >
                <Input
                  fullWidth
                  value={country}
                  startIcon={<SearchIcon />}
                  placeholder={accountsBankAccounts.ac_fee_typslde_subfoot_searchtxt}
                  onChange={(e) => handleLocation(e.target.value)}
                  helperText={accountsBankAccounts.ac_fee_typslde_subfoot_searchtxthint}
                />

                {country && renderResults()}
                {countryModal && <p>{countryTxt}</p>}
              </Wrap>
            </>
          )}
        </FindCountry.Root>
      </CountrySection>
    </>
  );
};
