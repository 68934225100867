import styled from "styled-components";
import { media } from "../../utils/use-media-query";

export const PostItemRoot = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 46%;
  min-width: 35rem;
`;

export const PostTitle = styled.h2`
  text-transform: uppercase;
  line-height: 6.7rem;
  min-height: 13rem;
 font-size: 3.6rem;
  line-height: 4.4rem;
  @media (${media.sm}) {
     {
      font-size: 32px;
      line-height: 48px;
      font-weight: 400;
      text-transform: none;
      padding-top: 60px;
    }
  }
`;

export const PostSubtitle = styled.h6`
  font-style: normal;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 3.6rem;
  margin: 6rem auto 2.2rem;
  color: ${(props) => props.theme.colors.text.primary};
  @media (${media.sm}) {
     {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
    }
  }
`;

export const PostDescriptiotn = styled.div`
  
  color: #d4d4d4;
  @media (${media.sm}) {
     {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
    }
  }
`;

export const PostImage = styled.img``;

const SecurityPostsRoot = styled.div`
  max-width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 4%;
  justify-content: center;

`;

export const SecPosts = {
  Root: SecurityPostsRoot,
  Item: {
    Root: PostItemRoot,
    Title: PostTitle,
    Image: PostImage,
    Descriptiotn: PostDescriptiotn,
    Subtitle: PostSubtitle,
  },
};
