import styled from "styled-components";
import { media } from "../../utils/use-media-query";

export const CultureCoreValuesMainDiv = styled.div<{ border: boolean }>`
  display: flex;
  align-items: center;
  padding: 40px 0px 40px 60px;
  border-bottom: ${(props) => (props.border ? "1px solid grey" : "")};
  @media (${media.sm}) {
     {
      line-height: 1.5;
      padding: 10px 0px 10px 0px;
    }
  }
`;

export const CultureCoreValuesImg = styled.img`
  width: 130px;
  height: 130px;
  @media (${media.sm}) {
     {
      width: 120px;
      height: 120px;
    }
  }
`;

export const CultureCoreValuesHeading = styled.h4`
  margin-bottom: 10px;
  font-size: 28px;
  @media (${media.sm}) {
     {
      font-size: 22px;

      line-height: 1.5;
      font-weight: 500;
    }
  }
`;

export const CultureCoreValuesText = styled.p`
  font-size: 20px;
  text-align: justify;
  @media (${media.sm}) {
     {
      font-size: 15px;
      line-height: 1.5;
    }
  }
`;
