import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  ResponsiveContainer,
  StackedCarousel,
  StackedCarouselSlideProps,
} from "react-stacked-center-carousel";
import { SlideSection } from "../../components/silde-section/slide-section.component";
import MainBgVideo from '../../assets/backgrounds/fees/fees_main_bg.webm';
import {
  MainSection,
  PlanInfo,
  Table,
} from "./cards.styles";
import { useMediaQuery } from "../../utils/use-media-query";
import { ReactComponent as BenefitsIcon } from "../../assets/icons/benefits.svg";
import { ReactComponent as FeesIcon } from "../../assets/icons/cards/fees.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as XMarkIcon } from "../../assets/icons/x-mark.svg";
import { PlanInfoType, TableItem } from "./cards.types";
import { uiDataWebsiteText, uiCardBenefits } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";
import { CardBenefitData } from "../../api/types/fetch.ui.types";
import { TextTitle } from "../../components/main-layout/text-content/text-content.styles";
import { Wrap } from "../../components/wrap/wrap.component";

const titlesForPercentage = [
  'BTC / ETH trading'
];

const titlesWithAlignTop = [
  'Opening Deposit',
  'Card Limits',
  'Physical Cards',
];

type SlideProps = StackedCarouselSlideProps & {
  onClick: (index: number, isCenterSlide: boolean) => void;
}

const Slide = React.memo(
  function (props: SlideProps) {
    const isTablet = useMediaQuery("md");

    const { data, dataIndex, onClick, isCenterSlide } = props;
    const cover = data[dataIndex];

    return (
      <div
        style={{ width: isTablet ? '100%' : '100%', height: !isTablet ? 500 : 'auto' }}
        onClick={() => {
          onClick(dataIndex, isCenterSlide)
        }}
      >
        <img
          alt="cover"
          style={{height: 'auto', width: '100%', objectFit: 'cover', borderRadius: 10}}
          draggable={false}
          src={cover}
        />
      </div>
    );
  },
  function (prev: StackedCarouselSlideProps, next: StackedCarouselSlideProps) {
    return prev.dataIndex === next.dataIndex;
  }
);

export const CardsPage: React.FC = (): JSX.Element => {
  const { accountsFees } = useSelector(uiDataWebsiteText);
  const cardBenefits = useSelector(uiCardBenefits);
  const [currentPlan, setCurrentPlan] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [planInfoItems, setPlanInfoItems] = useState<PlanInfoType[]>([]);

  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery("md");
  const { feeId } = useParams();

  const vidRef = useRef(null);
  const carouselRef = useRef<StackedCarousel>(null);

  const getPercentage = (value: string): string => `${(parseFloat(value) * 100).toFixed(2)}%`;

  const { features, fees, headers } = useMemo(() => {
    const headers = cardBenefits
      ?.filter((benefit: CardBenefitData) => benefit.ac_card_bnfts__is_this_sec_head === '1');
    const benefits = cardBenefits
      ?.filter((benefit: CardBenefitData) => benefit.ac_card_bnfts__is_this_sec_head !== '1')
      ?.map((benefit: CardBenefitData) => {
        const {
          ac_card_bnfts__title = '',
          ac_card_bnfts__sub_title = '',
          ac_card_bnfts__grp_a = '',
          ac_card_bnfts__grp_b = '',
          ac_card_bnfts__grp_c = '',
          ac_card_bnfts__grp_d = '',
          ac_card_bnfts__grp_cntnt_type = '',
        } = benefit;
        const data: TableItem = {
          title: ac_card_bnfts__title,
          description: ac_card_bnfts__sub_title,
          values: {
            black: ac_card_bnfts__grp_a,
            gold: ac_card_bnfts__grp_b,
            platinum: ac_card_bnfts__grp_c,
            titanium: ac_card_bnfts__grp_d,
          },
        }
        if (ac_card_bnfts__grp_cntnt_type === '2') {
          data.values = {
            black: ac_card_bnfts__grp_a === '1',
            gold: ac_card_bnfts__grp_b === '1',
            platinum: ac_card_bnfts__grp_c === '1',
            titanium: ac_card_bnfts__grp_d === '1',
          };
        } else if (
          ac_card_bnfts__grp_cntnt_type === '1' &&
          titlesForPercentage.includes(ac_card_bnfts__title)
        ) {
          data.values = {
            black: getPercentage(ac_card_bnfts__grp_a),
            gold: getPercentage(ac_card_bnfts__grp_b),
            platinum: getPercentage(ac_card_bnfts__grp_c),
            titanium: getPercentage(ac_card_bnfts__grp_d),
          };
        }
        return data;
      }) || [];

      return {
        headers,
        features: benefits?.slice(0, 19),
        fees: benefits?.slice(19),
      };
  }, [cardBenefits]);

  const getCardImages = (title: string) => {
    const planTitle = title.toLowerCase();
    if (!planTitle) return [];
    if (planTitle.includes('black') || planTitle.includes('gold')) {
      return [...Array(3)].map((item, index) => (
        `/assets/images/cards/${planTitle}/${(index % 3) + 1}.png`
      ));
    }
    return [`/assets/images/cards/${planTitle}/1.png`];
  };

  useEffect(() => {
    const {
      ac_card_bnfts__grp_a = '',
      ac_card_bnfts__grp_b = '',
      ac_card_bnfts__grp_c = '',
      ac_card_bnfts__grp_d = '',
    } = cardBenefits?.[0] || {};
    if (ac_card_bnfts__grp_a && !planInfoItems?.length) {
      setPlanInfoItems([
        {
          title: ac_card_bnfts__grp_a,
          tableTitle: accountsFees.ac_card_grp_a,
          images: getCardImages(ac_card_bnfts__grp_a),
        },
        {
          title: ac_card_bnfts__grp_b,
          tableTitle: accountsFees.ac_card_grp_b,
          images: getCardImages(ac_card_bnfts__grp_b),
        },
        {
          title: ac_card_bnfts__grp_c,
          tableTitle: accountsFees.ac_card_grp_c,
          images: getCardImages(ac_card_bnfts__grp_c),
        },
        {
          title: ac_card_bnfts__grp_d,
          tableTitle: accountsFees.ac_card_grp_d,
          images: getCardImages(ac_card_bnfts__grp_d),
        },
      ]);
    }
  }, [accountsFees, cardBenefits, planInfoItems]);

  const cardImages = useMemo(
    () => planInfoItems?.[currentPlan]?.images || [],
    [planInfoItems, currentPlan]
  );

  const cardCarousel = useMemo(() => {
    if (!cardImages.length) return null;

    return (
      <div style={{
        width: '100%',
        height: isTablet ? 400 : 500,
        position: 'relative',
        marginBottom: isMobile ? 24 : 50,
        marginTop: isMobile ? 24 : 50,
      }}>
        <ResponsiveContainer carouselRef={carouselRef} render={(parentWidth, ref) => {
          return (
            <StackedCarousel
              ref={ref}
              data={cardImages}
              carouselWidth={parentWidth}
              height={isTablet ? 600 : 500}
              slideWidth={isMobile ? parentWidth - 40 : (isTablet ? parentWidth * 0.75 : 750)}
              swipeSpeed={isMobile ? 0.1 : 0.5}
              transitionTime={isMobile ? 500 : 450}
              slideComponent={(props) => (
                <Slide
                  onClick={(index, isCenterSlide) => {
                    if (carouselRef.current && !isCenterSlide) {
                      if (
                        (selectedImage === 0 && index === 2) ||
                        (selectedImage === 1 && index === 0) ||
                        (selectedImage === 2 && index === 1)
                      ) {
                        carouselRef.current.goBack();
                      } else {
                        carouselRef.current.goNext();
                      }
                    }
                  }}
                  {...props}
                />
              )}
              onActiveSlideChange={(activeSlide) => {
                setSelectedImage(activeSlide)
              }}
              maxVisibleSlide={cardImages.length > 1 ? 3 : 1}
              currentVisibleSlide={cardImages.length > 1 ? 3 : 1}
              useGrabCursor={true}
              disableSwipe={true}
            />
          )}}
        />
      </div>
    );
  }, [carouselRef, cardImages, selectedImage, isMobile, isTablet]);

  useEffect(() => {
    if (feeId) setCurrentPlan(+feeId);
  }, [feeId]);

  useEffect(() => {
    if (cardImages.length === 1 || !isMobile) return;
    
    let nextIndex = 0;
    const intervalId = setInterval(() => {
      if (carouselRef.current) {
        nextIndex += 1;
        if (nextIndex > 2) {
          nextIndex = 0;
        }
        setSelectedImage(nextIndex);
        carouselRef.current.goNext();
      }
    }, 3000);
  
    return () => clearInterval(intervalId);
   
  }, [cardImages, carouselRef, isMobile]);

  const headerVideo = useMemo(() => (
    <Wrap
      sx={{
        position: "relative",
        height: isMobile ? "35rem" : "65rem",
        width: "100%",
        margin: '0 auto',
      }}
    >
      <video
        ref={vidRef}
        src={MainBgVideo}
        loop={true}
        autoPlay={true}
        muted={true}
        playsInline={true}
        style={{
          width: '100%',
          height: isMobile ? '100%' : 565,
          objectFit: isMobile ? 'cover' : 'fill',
          margin: 0,
        }}
      />
    </Wrap>
  ), [isMobile]);

  const renderTableRows = useCallback((arr: TableItem[]) => (
    arr.map(({ title, description, description2, sub1, sub2, values }, index) => {
      return (
        <tr key={`${title}-${index}`}>
          <td style={{
            ...(title && { paddingTop: 16 }),
            paddingRight: 24,
            ...(index !== arr.length - 1 && { paddingBottom: 16 })
          }}>
            {title && <Table.FeatureTitle>{title}</Table.FeatureTitle>}
            <Table.FeatureDescription>
              {description}
            </Table.FeatureDescription>
            {!!description2 && (
              <Table.FeatureDescription>
                {description2}
              </Table.FeatureDescription>
            )}
            {!!sub1 && (
              <Table.FeatureSubNote>
                {sub1}
              </Table.FeatureSubNote>
            )}
            {!!sub2 && (
              <Table.FeatureSubNote>
                {sub2}
              </Table.FeatureSubNote>
            )}
          </td>
          {Object.values(values).map((value, index) => {
            const selected = currentPlan === index;
            return (
              (isMobile && !selected)
                ? null
                : (
                  <Table.Data
                    key={`${value}-${index}`}
                    style={{
                      ...((typeof value !== 'boolean' && titlesWithAlignTop.includes(title)) && {
                        verticalAlign: 'top',
                      })
                    }}
                  >
                    {typeof value === 'boolean' ? (value ? <CheckIcon /> : <XMarkIcon />) : value}
                  </Table.Data>   
                )
            );
          })}
        </tr>
      );
    })
  ), [currentPlan, isMobile]);

  return (
    <>
      <HelmetContainer metaData={accountsFees.metaData} />
      <SlideSection
        bgImage=""
        title={accountsFees.ac_card_page_main_title}
        titleAlign={"center"}
        justify={"center"}
        customHeaderComponent={headerVideo}
        {...(!isMobile && !isTablet) ? {
          TitleSectionStyles: {
            marginTop: '8rem',
            justifyContent: 'start',
            alignItems: 'start',
          },
        } : {
          padding: 0,
          SectionStyles: {
            padding: 0,
          },
          TitleSectionStyles: {
            padding: 0,
          },
          titleStyles: {
            zIndex: 2,
            ...(isMobile ? {
              padding: 0,
              position: 'absolute',
              marginTop: '4rem',
              left: '10%',
              width: '80%',
            } : {
              position: 'absolute',
              left: '30%',
              top: '5rem',
            })
          }
        }}
      />
      <MainSection mainContent>
        <TextTitle style={{
          lineHeight: isMobile ? '3rem' : '4.5rem',
          textAlign: 'center',
          alignSelf: 'center',
          width: isMobile ? '100%' : (isTablet ? '80%' : '40%'),
          margin: 0,
          ...(isMobile && { padding: 0 })
        }}>
          {accountsFees.ac_card_page_title}
        </TextTitle>
        {cardCarousel}
        <PlanInfo.ButtonContainer>
          {planInfoItems.map(({ title }, index) => {
            const selected = planInfoItems[currentPlan]?.title === title;
            if (!title) return null;

            return (
              <PlanInfo.Button
                selected={selected}
                key={title}
                onClick={() => setCurrentPlan(index)}
              >
                {title.toLowerCase()}
              </PlanInfo.Button>
            );
          })}
        </PlanInfo.ButtonContainer>
        <PlanInfo.Title>
          {planInfoItems[currentPlan]?.tableTitle}
        </PlanInfo.Title>
        <table width="100%" style={{
          marginTop: isMobile ? 0 : 50,
        }}>
           <colgroup>
            {!isMobile ? (
              <>
                <col span={1} style={{ width: '40%' }} />
                <col span={1} style={{ width: '15%' }} />
                <col span={1} style={{ width: '15%' }} />
                <col span={1} style={{ width: '15%' }} />
                <col span={1} style={{ width: '15%' }} />
              </>
            ) : (
              <>
                <col span={1} style={{ width: '60%' }} />
                <col span={1} style={{ width: '40%' }} />
              </>
            )}
          </colgroup>
          <thead>
            <tr>
              <Table.Header selected>
                <Table.HeaderDiv>
                  <BenefitsIcon width={isMobile ? 48 : 63} height={isMobile ? 44 : 58} />
                  <Table.HeaderIconText>
                    {headers?.[0]?.ac_card_bnfts__title}
                  </Table.HeaderIconText>
                </Table.HeaderDiv>
              </Table.Header>
              {planInfoItems.map(({ title }) => {
                if (!title) return null;
                const selected = planInfoItems[currentPlan]?.title === title;

                if (isMobile && !selected) return null;
                return (
                  <Table.Header selected={selected} key={title}>
                    {title}
                  </Table.Header>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {renderTableRows(features)}
            <tr>
              <Table.Header selected style={{ paddingTop: 32 }}>
                <Table.HeaderDiv>
                  <FeesIcon width={isMobile ? 52 : 62} height={isMobile ? 42 : 50} />
                  <Table.HeaderIconText>
                    {headers?.[1]?.ac_card_bnfts__title}
                  </Table.HeaderIconText>
                </Table.HeaderDiv>
              </Table.Header>
            </tr>
            {renderTableRows(fees)}
          </tbody>
          <tfoot>
            <tr>
              <td style={{
                paddingRight: 24,
              }}>
                <Table.FeatureSubNote>
                  {accountsFees.ac_card_bnfts_note_a}
                </Table.FeatureSubNote>
                <Table.FeatureSubNote>
                  {accountsFees.ac_card_bnfts_note_b}
                </Table.FeatureSubNote>
              </td>
            </tr>
          </tfoot>
        </table>
      </MainSection>
    </>
  );
};
