import styled from "styled-components";
import { media } from "../../utils/use-media-query";

export const ComplaintsPolicyHeading = styled.h1`
    color :white;
    font-size: 40px;
    font-weight : 600;
    @media (${media.sm}) {
     {
        font-size: 2.5rem;
    }
  }
`;

export const ComplaintHeading = styled.p`
    color:white;
    font-size: 16px;
    margin-top: 109px;
    font-weight: 300;
    @media (${media.sm}) {
     {
        font-size: 2.5rem;
        line-height: 32px;
        font-weight: 500;
        text-align: left;
    }
  }
`
export const ComplaintContent = styled.p`
    color : #A1A1A1;
    margin-top : 4rem;
    font-weight: 300;
    @media (${media.sm}) {
     {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        text-align: left;
    }
  }

`;