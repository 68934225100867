import { Wrap } from "../../wrap/wrap.component";
import {
  TextDescription,
  TextMainContainer,
  TextMainTitle,
  TextMiniTitle,
  TextMiniTitleLeft,
  SpecialQuoteMessageWrapper,
  SpecialQuoteMessage,
  SpecialQuoteComa,
  SpecialQuoteComaReverse,
  TextSubTitle,
  TextTitle,
  TextTitleLeft,
} from "./text-content.styles";

import come from "../../../assets/icons/coma.svg";
import comeReverse from "../../../assets/icons/coma_reverse.svg";

export interface IPageContent {
  mainTitle?: string;
  title?: string;
  titleLeft?: string;
  miniTitle?: string;
  specialQuoteMessage?: any;
  miniTitleLeft?: string;
  inlineSubtitle?: string;
  upperSubtitle?: string;
  article?: string[];
  list?: string[];
  table?: { sign: string; title: string; titleLeft?: string }[];
  inlineList?: {
    inlineSubtitle?: string;
    article?: string[];
    titleLeft?: string;

    subtitleItalic?: boolean;
  }[];
  titledList?: {
    title?: string;
    titleLeft?: string;
    upperSubtitle?: string;
    article?: string[];
    list?: string[];
    italic?: boolean;
    miniTitle?: string;
  }[];
}

interface contentProps {
  content: IPageContent[];
}

export const TextContent: React.FC<contentProps> = ({ content }: contentProps): JSX.Element => {
  return (
    <TextMainContainer>
      {content?.map((root) => (
        <>
          {root.mainTitle && <TextMainTitle>{root.mainTitle}</TextMainTitle>}

          {root.title && <TextTitle>{root.title} </TextTitle>}
          {root.titleLeft && <TextTitleLeft>{root.titleLeft}</TextTitleLeft>}
          {root.miniTitle && <TextMiniTitle>{root.miniTitle} </TextMiniTitle>}
          {root.miniTitleLeft && <TextMiniTitleLeft>{root.miniTitleLeft} </TextMiniTitleLeft>}
          {root.specialQuoteMessage &&
            <SpecialQuoteMessageWrapper>
              <SpecialQuoteMessage>{root.specialQuoteMessage}</SpecialQuoteMessage>
              <SpecialQuoteComa src={come} />
              <SpecialQuoteComaReverse src={comeReverse} />
            </SpecialQuoteMessageWrapper>
          }
          {root.inlineList &&
            root.inlineList.map((list) => (
              <TextDescription>
                <TextSubTitle italic={list.subtitleItalic}>{list.inlineSubtitle} </TextSubTitle>
                {list.article}
              </TextDescription>
            ))}
          {root.titledList &&
            root.titledList.map((element) => (
              <Wrap sx={{ marginTop: "1.4rem" }}>
                {element.upperSubtitle && (
                  <>
                    <TextSubTitle italic={element.italic}>{element.upperSubtitle}</TextSubTitle>
                  </>
                )}
                {element.miniTitle && <TextMiniTitle>{element.miniTitle} </TextMiniTitle>}

                {element.article.map((art) => (
                  <Wrap>
                    <TextDescription italic={element.italic}>{art}</TextDescription>
                  </Wrap>
                ))}
                {element.list && (
                  <Wrap sx={{ marginBottom: "1.4rem" }}>
                    <TextDescription>
                      {element.list?.map((list) => (
                        <span>
                          {list}
                          <br />
                        </span>
                      ))}
                    </TextDescription>
                  </Wrap>
                )}
              </Wrap>
            ))}
          {root.article &&
            root.article.map((ar) => (
              <TextDescription>
                {root.inlineSubtitle && <TextSubTitle>{root.inlineSubtitle}</TextSubTitle>}
                {ar}
              </TextDescription>
            ))}
          {root.table && (
            <Wrap
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "33% 33% 33%",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "5rem 0",
              }}
            >
              {root.table.map((el) => (
                <TextTitle>
                  <Wrap sx={{ display: "column", alignItems: "center", width: "100%", textAlign: "center" }}>
                    <Wrap sx={{ fontSize: "5rem", marginBottom: "1rem" }}>{el.sign}</Wrap>
                    <div>{el.title}</div>
                  </Wrap>
                </TextTitle>
              ))}
            </Wrap>
          )}
        </>
      ))}
    </TextMainContainer>
  );
};