import styled from "styled-components";
import { media } from "../../utils/use-media-query";
import { Section } from "../../components/section/section.component";

export type InfoBlockContainerProps = {
  reverse?: boolean;
};
export type ImgWrapperProps = {
  reverse?: boolean;
};
export const DescriptionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "column1 column2"; 
  @media (${media.sm}) {
    grid-template-columns: 1fr;
    grid-template-areas: "column2" "column1"; /* Reverse the order for small screens */
    grid-gap: 30px; 
  }
`;

export const ProfsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-area: column1;
  @media (${media.sm}) {
    /* margin-bottom: 5.1rem; */
  }
`;
export const ProfItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (${media.sm}) {
    margin-bottom: 5.1rem;
  }
`;

export const ProfItemTitle = styled.h5`
  text-transform: uppercase;
  font-size: 2.4rem;
  line-height: 4.8rem;
  @media (${media.sm}) {
    text-transform: initial;
    font-size: 2rem;
    text-align: center;
    font-weight: 400;
  }
`;

export const ProfItemDescription = styled.div`
  font-size: 2rem;
  line-height: 2.8rem;
  @media (${media.sm}) {
    font-size: 15px;
    line-height: 1.5;
    text-align: center;
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: column2;
`;

export const DescriptionTitle = styled.h2`
  text-transform: uppercase;
  @media (${media.sm}) {
    font-size: 2rem;
    text-align: center;
    
  }
`;

export const Description = styled.div`
  line-height: 2.8rem;
  font-size: 2rem;
  margin-top: 2.4rem;
  text-align: justify;
  @media (${media.sm}) {
    font-size: 1.5rem;
    line-height: 1.5;
    
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  width: 55%;
  flex-direction: column;
  @media (${media.sm}) {
    width: 100%;
  }
`;

export const BlockTitle = styled.h4`
  text-transform: uppercase;
  // letter-spacing: 0.16em;
  font-size: 3.2rem;
  @media (${media.sm}) {
    /* font-weight: 400; */
    font-size: 2rem;
    text-align: center;
    /* text-align: right;
    letter-spacing: 0.16em;
    color: #f2f2f2;
    margin-bottom: 2rem; */
    font-weight: 400;
  }
`;

export const BlockSubtitle = styled.h5`
  line-height: 3rem;
  margin-top: 0.6rem;
  margin-bottom: 4.7rem;
  @media (${media.sm}) {
    font-size: 1.8rem;
    text-align: center;
    font-weight: 400;
  }
`;

export const InfoListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  width:50%;
  height:fit-content;
  column-gap: 2rem;
  @media (${media.sm}) {
    grid-template-columns: 1fr;
    margin-bottom: 3rem;
      width:100%;
  }
`;

export const InfoListItem = styled.li`
  line-height: 22px;
  flex:1;
`;

export const ImgWrapper = styled.div<InfoBlockContainerProps>`
  display: flex;
  width: 50%;
  
  justify-content: ${(props) => (props.reverse ? "flex-end" : "flex-start")};
  align-self: center;
  padding-inline: 50px;
  @media (${media.sm}) {
    justify-content: center;
    margin: 1rem inherit;
    align-self: center;
    width: 65%;
  }
  
`;

export const InfoBlockContainer = styled.div<InfoBlockContainerProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  margin-bottom: 18rem;
  :last-child {
    margin-bottom: 10rem;
  }
  /* ${ImgWrapper} {
    margin: ${(props) => (!props.reverse ? "0 0 0 10rem" : "0 10rem 0 0")};
  } */
  @media (${media.sm}) {
    flex-direction: column;
    margin-bottom: 7rem;
    :last-child {
      margin-bottom: 5rem;
    }
    ${ImgWrapper} {
      /* margin: 3rem; */
    }
  }
`;

export const CountrySection = styled(Section)`
  overflow: hidden;
  padding: 0rem 15rem 20rem 15rem;
  @media (${media.sm}) {
    padding: 2rem;
    margin-top: 3rem;
  }
`;

const FindCountryRoot = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas:
    "country title"
    "country search";
  grid-template-columns: 1fr 76rem;
  grid-column-gap: 8rem;
  grid-row-gap: 2.4rem;

  @media (${media.md}) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const FindCountryTitle = styled.h3`
  line-height: 4.7rem;
  
  @media (${media.sm}) {
    font-size: 2rem;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
  }

`;

const FindCountryListItem = styled.div`
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: ${(props) => props.theme.colors.text.secondary};

  :not(:last-child) {
    margin-bottom: 2.8rem;
  }

  @media (${media.md}) {
    margin-bottom: 0 !important;
  }
  @media (${media.sm}) {
    font-size: 15px;
    text-align: center;
  }
`;

const SupportTitle = styled.h5`
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  margin-bottom: 1rem;
`;
const ResultText = styled.div`
  background: black;
  border-radius: 16px;
  border: 1px solid #f2f2f2;
  color: white;
  outline: none;
  align-items: center;
  padding: 2rem;
  height: 100%;
  max-height: 180px;
  overflow-y: scroll;
  border-color: rgba(242, 242, 242, 0.2);
  font-family: "Open Sans", sans-serif;
`;

export const FindCountry = {
  Root: FindCountryRoot,
  Title: FindCountryTitle,
  SupportTitle: SupportTitle,
  Result: ResultText,
  Countries: {
    Item: FindCountryListItem,
  },
};
