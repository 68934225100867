import styled from "styled-components";
import { media } from "../../utils/use-media-query";
export const MissionTabDiv = styled.div`
  display: flex;

  width: 100%;
  @media (max-width: 1450px) {
    flex-direction: column;
    justify-content: center;
  }
`;
export const MissionTabMainBlock = styled.div<{ borderRight?: boolean; borderBottom?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  padding: 20px 0;
  align-items: center;
  border-right: ${(props) => (props.borderRight ? "1px solid #353535" : "")};
  border-bottom: ${(props) => (props.borderBottom ? "1px solid #353535" : "")};
  @media (${media.lg}) {
    border-right: none;
  }
`;
export const MissionTabHeading = styled.h1`
  color: white;
  font-size: 25px;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 992px) {
    text-align: center;
  }
  @media (${media.sm}) {
    font-size: 2rem;

    line-height: 3rem;
    text-align: center;
    margin-bottom: 30px;
  }
  @media (${media.sm}) {
  }
`;
export const MissionTabText = styled.p`
  color: #c8c8c8;
  font-size: 22px;
  align-items: center;

  margin: 40px 0px;
  @media (${media.md}) {
    margin: 20px 0px;
    font-size: 3vw;
    text-align: center;
  }
  @media (${media.sm}) {
    margin: 20px 0px;
    font-size: 16px;
    text-align: center;
  }
`;
export const KeyMetricsDiv = styled.div`
  /* margin: 30px 0px 70px 0px; */
  margin: 0rem auto;

  max-width: 100%;
`;
export const KeyMetricsMainDiv = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  @media (max-width: 1450px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;
export const KeyMetricsContentDiv = styled.div<{
  borderRight?: boolean;
  borderBottom?: boolean;
  fullWidth?: boolean;
  order?: string;
  marginTop?: string;
}>`
  display: flex;
  align-items: center;
  padding: 50px 50px;
  max-width: 500px;
  flex: 1;
  border-right: ${(props) => (props.borderRight ? "1px solid #353535" : "")};
  border-bottom: ${(props) => (props.borderBottom ? "1px solid #353535" : "")};
  order: ${(props) => (props.order ? props.order : null)};
  justify-content: space-between;
  width: 100%;

  @media (${media.lg}) {
    width: ${(props) => (props.fullWidth ? "100%" : "50%")};
    justify-content: center;
    gap: 10px;
    padding: 50px;
  }
  @media (${media.sm}) {
    width: ${(props) => (props.fullWidth ? "100%" : "50%")};
    justify-content: space-between;
    column-gap: 30px;
    border: none;
    padding: 25px 30px;
    flex-direction: row;
  }
  @media (${media.xxs}) {
    column-gap: 20px;
    margin-top: ${(props) => props.marginTop && props.marginTop};
    padding: 25px 0px;
  }
`;
export const KeyMetricsMainText = styled.h1<{
  fontSize?: string;
}>`
  color: white;
  height: 50px;
  font-weight: 700;
  font-size: 51px;
  width: 100%;
  margin: 20px 0;
  @media (max-width: 1450px) {
    font-size: 28px;
  }
  @media (${media.sm}) {
    font-size: ${(props) => (props.fontSize || "51px")};
    margin:  0;
  }
  @media (${media.xxs}) {
    font-size: ${(props) => (props.fontSize || "36px")};
    white-space: nowrap;
  }
`;
export const KeyMetricsSecondaryText = styled.p`
  color: white;

  font-size: 24px;
  @media (max-width: 1450px) {
    font-size: 16px;
  }
  @media (${media.sm}) {
    position: relative;
    font-size: 24px;
  }
  @media (${media.xxs}) {
    position: relative;
    line-height: 18px;
    left: 0px;
    font-size: 16px;
    margin-top: 0;
  }
`;
export const KeyMetricsYearsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  position: relative;
  align-self: center;
  margin: 5rem 0px 20rem 0px;
  @media (${media.md}) {
    margin-right: auto;
    margin-bottom: 30rem;
  }
  @media (${media.xxs}) {
    margin-right: auto;
    margin-bottom: 40rem;
  }
`;
export const KeyMetricsCircleDiv = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;

  background-color: #505050;
`;
export const KeyMetricsHorizontalDiv = styled.div`
  height: 100px;
  width: 1px;
  border: 1px solid #505050;
  @media (${media.md}) {
    height: 150px;
  }
  @media (${media.sm}) {
    height: 90px;
  }


`;
export const KeyMetricsTextDiv = styled.div<{ align: string; position: string }>`
  position: absolute;
  width: 400px;

  text-align: ${(props) => props.align};
  ${(props) => props.position}: 15%;
  @media screen and (max-width: 1400px) {
    ${(props) => props.position}: 2%;
    right: 0;
    width: 43%;
  }
`;
export const KeyMetricsHeading = styled.h5`
  font-size: 28px;
  line-height: 2rem;
  margin-bottom: 8px;
  @media (${media.sm}) {
    font-size: 16px;
    font-weight: 400;
  }
`;
export const KeyMetricsText = styled.p`
  font-size: 20px;

  @media (max-width: 1200px) {
    
  }
  @media (${media.md}) {
    font-size: 2rem;

    line-height: 26px;
  }
  @media (${media.sm}) {
    font-size: 12px;
    line-height: 1.5;
  }

`;
export const KeyMetricsTableImg = styled.img<{ width?: number }>`
  object-fit: contain;
  width: 150px;
  height: 150px;
  @media (max-width: 1450px) {
    width: ${(props) => (props.width || "70px")};
    height: 20vh;
  }
  @media (${media.sm}) {
    width: 150px;
    height: 150px;
  }
`;
export const KeyMetricsImg = styled.img<{ opacity?: boolean }>`
  opacity: ${(props) => (props.opacity ? 0 : "1")};
`;
export const KeyMetricsArrowImg = styled.img`
  width: 100px;
  height: 50px;
  margin: 0px 40px 45px 40px;
  align-self: center;
  @media (${media.sm}) {
    width: 50px;
    margin: 20px ;
  }
`;
