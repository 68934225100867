import { media } from "../../utils/use-media-query";
import { BenefitsProps } from "./benefits.types";
import styled from "styled-components";

export const BenefitItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (${media.sm}) {
     {
    /* flex-direction: row; */
    }
  };

`;

export const BenefitIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  @media (${media.sm}) {
     {
      /* padding-right: 30px; */
      width: 33%;
    }
  }
`;

export const BenefitTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 5.7rem;
  text-align: center;
  text-transform: uppercase;
  margin: 2rem 0 1rem;
  color: ${(props) => props.theme.colors.text.primary};
  @media (${media.sm}) {
     {
      font-size: 20px;
      line-height: 32px;
      font-weight: 600;
      letter-spacing: 0.39px;
      margin: 0rem;
      text-align: center;
      padding: 10px;
    }
  }
`;

export const BenefitsDescription = styled.h5`
  letter-spacing: 0.015em;
  text-align: center;
  font-size: 2rem;
  font-weight: 400;
  @media (${media.sm}) {
     {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      letter-spacing: 0.39px;
    }
  }
`;

export const BenefitsContainer = styled.div<Omit<BenefitsProps, "items">>`
  width: 100%;
  display: grid;
  column-gap: 5rem;
  row-gap: 5rem;
  @media (${media.sm}) {
     {
      row-gap: 4rem;
    }
  }
  grid-template-columns: ${(props) => (props.vertical ? "1fr" : "1fr 1fr 1fr")};
  ${(props) =>
    props.secondary &&
    `
    ${BenefitTitle} {
      font-style: normal;
      font-weight: 500;
      // font-size: 3.2rem;
      line-height: 4.8rem;
      text-align: center;
      color: #F2F2F2;
      text-transform: none;
    };
    ${BenefitsDescription} {
      font-style: normal;
      font-weight: 400;
      color: #A3A3A3;
    };
    ${BenefitIconContainer} {
      justify-content: flex-start;
    };
    ${BenefitItemContainer} {
      align-items: flex-start;
    }
  `}
  ${(props) =>
    props.centered &&
    `
      ${BenefitItemContainer} {
          align-items: center ;
    }
  `}
`;
