import styled from "styled-components";
import { media } from "../../utils/use-media-query";
import { ButtonContainer } from "../../components/button/buton.styles";
import { Section } from "../../components/section/section.component";
const PlanInfoRoot = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 144rem;
`;

const PlanInfoWrapper = styled.div<{ visibleSize: number; currentSlide: number }>`
  display: flex;
  flex-wrap: nowrap;
  min-width: max-content;
  height: 100%;
  transition: all 300ms ease-in-out;
  transform: translateX(-${(props) => props.visibleSize * props.currentSlide}px);
`;

const PlanInfoTitle = styled.h2`
  width: 100%;
  font-size: 42px;
  text-align: center;
  margin-top: 80px;
  @media (${media.sm}) {
    margin: 4rem auto;
    text-align: center;
    font-size: 25px;
  }
`;

const PlanInfoDescriptionRoot = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 6rem;
  grid-template-areas: "column1 column2"; 
  @media (${media.sm}) {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const PlanInfoImg = styled.img`
  position: relative;
  width: 70%;
  @media (${media.md}) {
    margin: 3rem auto !important;
  }
`;
const PlanInfoButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  margin-top: 50px;
  z-index: 2;
  @media (${media.sm}) {
    column-gap: 4px;
    justify-content: space-evenly;
    margin-top: 25px;
  }
`;
const PlanInfoButton = styled(ButtonContainer)<{selected: boolean}>`
  border-radius: 100px;
  padding: 1.2rem 2.6rem;
  font-weight: 500;
  font-size: 19px;
  text-transform: capitalize;
  border: none;
  &:hover {
    background: rgba(255, 255, 255, .25) !important;
    color: ${(props) => props.theme.colors.text.secondary};
    border: none;
  }
  &:active {
    background-color: rgba(255, 255, 255, .15) !important;
    border: none;
  }
  ${(props) => 
    props.selected
    ? `
        border: none;
        background-color: rgba(255, 255, 255, .15) !important;
        color: ${props.theme.colors.text.secondary} !important;
      `
    : `
        border: 2px solid #313131;
        color: #313131;
        background-color: transparent;
      `
  }
  @media (${media.sm}) {
    padding: 1rem 1.5rem;
    font-size: 15px;
  }
`;

const TableHeader = styled.th<{
  selected?: boolean,
}>`
  font-weight: 500;
  font-size: 24px;
  color: ${(props) => props.selected ? 'white' : '#313131'};
  text-transform: capitalize;
  @media (${media.sm}) {
    font-size: 20px;
  }
`;
const TableHeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
`;
const TableHeaderIconText = styled.th`
  font-weight: 700;
  font-size: 25px;
  @media (${media.sm}) {
    font-size: 20px;
  }
`;
const TableFeatureTitle = styled.p`
  font-weight: 700;
  font-size: 18px;
  color: white;
`;
const TableFeatureDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: #D4D4D4;
`;
const TableFeatureSubNote = styled.p`
  font-weight: 400;
  font-size: 11px;
  color: #D4D4D4;
`;
const TableData = styled.td`
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  padding: 8px;
  white-space: pre-wrap;
  @media (${media.sm}) {
    font-weight: bold;
    line-height: 18px;
  }
`;

export const MainSection = styled(Section)`
  overflow: hidden;
  padding: 10rem;
  margin-top: 3rem;
  @media (${media.sm}) {
    padding: 7rem 2rem;
    margin-top: 1rem;
  }
`;

export const CountrySection = styled(Section)`
  overflow: hidden;
  padding: 15rem;
  @media (${media.sm}) {
    padding: 2rem;
    margin-top: 3rem;
  }
`;

export const SwiperDiv = styled.div`
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (${media.sm}) {
    margin-top: 3rem;
    max-height: 500px;
    display: flex;
    align-items: center;
  }
`;

export const PlanInfo = {
  Wrapper: PlanInfoWrapper,
  Root: PlanInfoRoot,
  Title: PlanInfoTitle,
  Description: PlanInfoDescriptionRoot,
  Img: PlanInfoImg,
  ButtonContainer: PlanInfoButtonContainer,
  Button: PlanInfoButton,
};

export const Table = {
  Header: TableHeader,
  HeaderDiv: TableHeaderDiv,
  HeaderIconText: TableHeaderIconText,
  FeatureTitle: TableFeatureTitle,
  FeatureDescription: TableFeatureDescription,
  FeatureSubNote: TableFeatureSubNote,
  Data: TableData,
};
