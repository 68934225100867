import styled from "styled-components";
import { media } from "../../utils/use-media-query";

export const SustainabilitySecondTextMainDiv = styled.div`
  display: flex;
  justify-content:space-between;
  margin: 60px 0px 50px 0px;
  @media (max-width: 1200px) {
    flex-direction: column;
    row-gap: 30px;
  }
`;
export const SustainabilitySecondTextSecondaryDiv = styled.div`
  display: flex;
  flex: 1;
  margin:0 2rem;
  flex-direction: column;
`;
export const SustainabilitySecondTextImg = styled.img`
  width: 170px;
  height: 170px;
  align-self: center;
  @media (${media.sm}) {
     {
      align-self: center;
      width: 70px;
      height: 70px;
    }
  };
  @media (${media.md}) {
     {
      align-self: center;
 
    }
  };
`;
export const SustainabilitySecondTextHeading = styled.h3`
  font-size: 28px;
  @media (${media.sm}) {
     {
      font-size: 16px;
      text-align: center;
      font-weight: 600;
    }
  };
`;
export const SustainabilitySecondText = styled.p`
  font-size: 20px;
  @media (max-width: 1200px) {
    
  }
  @media (${media.sm}) {
     {
      font-size: 12px;
      line-height: 16.34px;
      text-align: center;
    }
  };
`;
export const SustainabilityTabMainDiv = styled.div`
  display: flex;
  column-gap: 30px;
  width: 100%;
  margin: 50px 0px 100px 0px;
  @media (${media.md}) {
    flex-direction: column;
    row-gap: 20px;
  };
  @media (${media.sm}) {
    flex-direction: column;
    row-gap: 32px;
  };
`;
export const SustainabilityTabBorderDiv = styled.div<{ year: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) => (props.year ? "10px 100px" : "0px 50px")};
  border: 1px solid #7d848b;
  border-radius: 10px;
  height: 100%;
  margin-top: 30px;
  text-align: center;
  @media (${media.sm}) {
     {
      padding: ${(props) => (props.year ? "10px 100px" : "0px 16px")};
      margin-top: ${(props) => (props.year ? "24px" : "0px")};
    }
  };
`;
export const SustainabilityTabSecondaryDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  @media (${media.sm}) {
    column-gap: 20px;
  }
`;
export const SustainabilityTabHeading = styled.h5`
  font-size: 28px;
  @media (${media.sm}) {
    font-size: 15px;
  };
`;
export const SustainabilityTabProgress = styled.progress`
  height: 40px;
  accent-color: #9ca5ad;
  margin-right: 40px;
  width: 280px;
  min-width: 250px;
`;
export const SustainabilityTabPerformance = styled.p`
  color: white;
  font-size: 28px;
  @media (${media.sm}) {
     {
      font-size: 15px;
    }
  };
`;
export const SustainabilityTabText = styled.p<{ color: string }>`
  color: ${(props) => (props.color ? "#A1A1A1" : "white")}; ;
  font-size: 20px;
  width: 60%;
  text-align: center;
  @media (${media.sm}) {
    text-align: left;
    font-size: 12px;
    line-height: normal;
  };
`;
