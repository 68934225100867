import { Base64 } from "js-base64";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchAboutUsFinanStats,
  fetchCareerDropDown,
  fetchCareerJobs,
  fetchCountryOfResidence,
  fetchGlobalOfficeDropDown,
  fetchLanguageList,
  fetchGlobalOffices,
  fetchMediaCenterInvestorNews,
  fetchMediaCenterNews,
  fetchWebsiteText,
  fetchWebSiteTextAll,
  fetchSendCompanyMessage,
  fetchAboutUsFinanDropDown,
  fetchScripts,
  fetchAllLeadershipData,
  fetchLeadershipPersonData,
  fetchCardBenefitData,
} from "../../api/services/fetch.ui";
import { setUiDataFetching, setUsLeadershipPerson } from "./slice";
import { LeadershipPersonDataType } from "../../api/types/fetch.ui.types";

const getLanguages = createAsyncThunk("/languageList", async (credentials, { dispatch }) => {
  try {
    const { data } = await fetchLanguageList();
    return data.data;
  } catch (err) {
    return Promise.reject(err);
  }
});

const getGlobalOffices = createAsyncThunk(
  "/globalOffices",
  async (credentials: { languageId: string; countryCode: string }, { dispatch }) => {
    try {
      const { data } = await fetchGlobalOffices(credentials);
      return data.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

const getCareerDropDown = createAsyncThunk(
  "/careerDropDown",
  async (credentials: { languageId: string }, { dispatch }) => {
    try {
      const { data } = await fetchCareerDropDown(credentials);
      return data.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
// const getWebsiteText = createAsyncThunk(
//   "/websiteText",
//   async (credentials: { pageId: string; languageId: string }, { dispatch }) => {
//     dispatch(setUiDataFetching(true));
//     try {
//       const { data } = await fetchWebsiteText(credentials);
//       dispatch(setUiDataFetching(false));
//       return data.data;
//     } catch (err) {
//       return Promise.reject(err);
//     }
//   },
// );

const getCareerJobs = createAsyncThunk(
  "/careerJobs",
  async (credentials?: {
    languageId?: string;
    jobLocation?: number;
    postedDays?: number;
    keywords?: string;
    jobKeywordMatch?: number;
  }) => {
    try {
      if (!credentials.jobLocation) {
        delete credentials.jobLocation;
      }
      if (!credentials.postedDays) {
        delete credentials.postedDays;
      }
      const { data } = await fetchCareerJobs(credentials);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

const getAllWebsiteText = createAsyncThunk("/websiteText", async (credentials: string, { dispatch }) => {
  dispatch(setUiDataFetching(true));
  try {
    const data = await fetchWebSiteTextAll(credentials);
    dispatch(setUiDataFetching(false));
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
});

const getCountryOfResidence = createAsyncThunk(
  "/countryOfResidence",
  async (credentials: { languageId: string }, { dispatch }) => {
    try {
      const { data } = await fetchCountryOfResidence(credentials);
      return data.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
const getGlobalOfficeDropDown = createAsyncThunk(
  "/globalOfficeDropDown",
  async (credentials: { languageId: string }, { dispatch }) => {
    try {
      const { data } = await fetchGlobalOfficeDropDown(credentials);
      return data.data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
const getScripts = createAsyncThunk("/metaScriptDatas", async (credentials, { dispatch }) => {
  try {
    const { data } = await fetchScripts();
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
});

const getMediaCenterInvestorNews = createAsyncThunk(
  "/mediaCenterInvestorNews",
  async (credentials: { languageId: string }, { dispatch }) => {
    try {
      const data = await fetchMediaCenterInvestorNews(credentials);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

const getMediaCenterNews = createAsyncThunk(
  "/mediaCenterNews",
  async (credentials: { languageId: any; mnId?: any }, { dispatch }) => {
    try {
      const data = await fetchMediaCenterNews(credentials);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

const getAboutUsLeadership = createAsyncThunk(
  "/getAllLeadershipData",
  async (credentials: { languageId: string }, { dispatch }) => {
    try {
      const { data } = await fetchAllLeadershipData(credentials);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
const getAboutUsLeadershipPerson = createAsyncThunk(
  "/getLeadershipData",
  async (credentials: { languageId: string; linkId: string }, { dispatch }) => {
    try {
      const { data } = await fetchLeadershipPersonData(credentials);
      dispatch(
        setUsLeadershipPerson(
          data.data.map((item: LeadershipPersonDataType) => {
            let obj: any = {};
            Object.entries(item)?.map((el) => {
              obj[el[0]] = Base64.decode(el[1]);
            });

            return obj;
          })?.[0],
        ),
      );
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
const getAboutUsFinanStats = createAsyncThunk(
  "/aboutUsFinanStats",
  async (credentials: { languageId: string; finDataId?: string }, { dispatch }) => {
    try {
      const { data } = await fetchAboutUsFinanStats(credentials);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

const getAboutUsFinanDropDown = createAsyncThunk(
  "/aboutUsFinanDropDown",
  async (credentials: { languageId: string }, { dispatch }) => {
    try {
      const { data } = await fetchAboutUsFinanDropDown(credentials);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

const postSendCompanyMessage = createAsyncThunk(
  "/contactBlackBanxEmail",
  async (
    credentials: { languageId: string; destination: string; subject: string; mailContent: string },
    { dispatch },
  ) => {
    try {
      const { data } = await fetchSendCompanyMessage(credentials);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

const getCardBenefitData = createAsyncThunk(
  "/getAcCardBenefitData",
  async (credentials: { languageId: string; }, { dispatch }) => {
    try {
      const { data } = await fetchCardBenefitData(credentials);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
// actions.ts

export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";

export interface SetSelectedLanguageAction {
  type: typeof SET_SELECTED_LANGUAGE;
  payload: string;
}

export const setSelectedLanguage = (languageId: string): SetSelectedLanguageAction => ({
  type: SET_SELECTED_LANGUAGE,
  payload: languageId,
});

const operations = {
  getLanguages,
  getGlobalOffices,
  // getWebsiteText,
  getAllWebsiteText,
  getCountryOfResidence,
  getGlobalOfficeDropDown,
  getScripts,
  getMediaCenterInvestorNews,
  getMediaCenterNews,
  getCareerDropDown,
  getCareerJobs,
  getAboutUsLeadership,
  getAboutUsLeadershipPerson,
  getAboutUsFinanStats,
  getAboutUsFinanDropDown,
  getCardBenefitData,
  postSendCompanyMessage,
};
export default operations;
