import { SlideSection } from "../../components/silde-section/slide-section.component";
import MissionBg from "../../assets/backgrounds/mission/home_slider.png";
import { Section } from "../../components/section/section.component";
import { useMediaQuery } from "../../utils/use-media-query";
import { uiDataWebsiteText } from "../../redux/uiData/selectors";
import { useSelector } from "../../redux/store";
import { Wrap } from "../../components/wrap/wrap.component";
import { IPageContent, TextContent } from "../../components/main-layout/text-content/text-content";
import { RootlikeBlockScheme, RootlikeBlockSchemeProps } from "../../components/tree-block-scheme/tree-block-scheme";
import parse from "html-react-parser";
import map from "../../assets/icons/goverance_map.svg";
import {
  GovernanceDivider,
  GovernanceDividerColor,
  GovernanceDividerText,
  GovernanceHeading,
  GovernanceMapImg,
  GovernanceText,
  GovernanceTreeBlock,
  GovernanceTreeBlockName,
  GovernanceTreeBlockPlace,
} from "./governance.styles";

import { Helmet } from "react-helmet";
import { HelmetContainer } from "../../components/helmetContainer/helmetContainer.component";

export const GovernancePage = () => {
  const { governance } = useSelector(uiDataWebsiteText);
  const isMobile = useMediaQuery("sm");

  const treeColumnOne = [
    {
      name: governance.abtus_gvrn_bst_grpOne_orgOne_name,
      place: governance.abtus_gvrn_bst_grpOne_orgOne_place,
      fontSize: true,
    },
    {
      name: governance.abtus_gvrn_bst_grpOne_orgTwo_name,
      place: governance.abtus_gvrn_bst_grpOne_orgTwo_place,
      fontSize: false,
    },
    {
      name: governance.abtus_gvrn_bst_grpOne_orgThree_name,
      place: governance.abtus_gvrn_bst_grpOne_orgThree_place,
      fontSize: false,
    },
    {
      name: governance.abtus_gvrn_bst_grpOne_orgFour_name,
      place: governance.abtus_gvrn_bst_grpOne_orgFour_place,
      fontSize: false,
    },
  ];
  const treeColumnTwo = [
    {
      name: governance.abtus_gvrn_bst_grpTwo_orgOne_name,
      place: governance.abtus_gvrn_bst_grpTwo_orgOne_place,
      fontSize: true,
    },
    {
      name: governance.abtus_gvrn_bst_grpTwo_orgTwo_name,
      place: governance.abtus_gvrn_bst_grpTwo_orgTwo_place,
      fontSize: false,
    },
    {
      name: governance.abtus_gvrn_bst_grpTwo_orgThree_name,
      place: governance.abtus_gvrn_bst_grpTwo_orgThree_place,
      fontSize: false,
    },
    {
      name: governance.abtus_gvrn_bst_grpTwo_orgFour_name,
      place: governance.abtus_gvrn_bst_grpTwo_orgFour_place,
      fontSize: false,
    },
  ];
  const treeColumnThree = [
    {
      name: governance.abtus_gvrn_bst_grpThree_orgOne_name,
      place: governance.abtus_gvrn_bst_grpThree_orgOne_place,
      fontSize: true,
    },
    {
      name: governance.abtus_gvrn_bst_grpThree_orgTwo_name,
      place: governance.abtus_gvrn_bst_grpThree_orgTwo_place,
      fontSize: false,
    },
    {
      name: governance.abtus_gvrn_bst_grpThree_orgThree_name,
      place: governance.abtus_gvrn_bst_grpThree_orgThree_place,
      fontSize: false,
    },
    {
      name: governance.abtus_gvrn_bst_grpThree_orgFour_name,
      place: governance.abtus_gvrn_bst_grpThree_orgFour_place,
      fontSize: false,
    },
  ];
  const treeColumnFour = [
    {
      name: governance.abtus_gvrn_bst_grpFour_orgOne_name,
      place: governance.abtus_gvrn_bst_grpFour_orgOne_place,
      fontSize: true,
    },
    {
      name: governance.abtus_gvrn_bst_grpFour_orgTwo_name,
      place: governance.abtus_gvrn_bst_grpFour_orgTwo_place,
      fontSize: false,
    },
  ];
  const treeColumnFive = [
    {
      name: governance.abtus_gvrn_bst_grpFive_orgOne_name,
      place: governance.abtus_gvrn_bst_grpFive_orgOne_place,
      fontSize: true,
    },
    {
      name: governance.abtus_gvrn_bst_grpFive_orgTwo_name,
      place: governance.abtus_gvrn_bst_grpFive_orgTwo_place,
      fontSize: false,
    },
    {
      name: governance.abtus_gvrn_bst_grpFive_orgThree_name,
      place: governance.abtus_gvrn_bst_grpFive_orgThree_place,
      fontSize: false,
    },
    {
      name: governance.abtus_gvrn_bst_grpFive_orgFour_name,
      place: governance.abtus_gvrn_bst_grpFive_orgFour_place,
      fontSize: false,
    },
  ];

  const upperTextValue: IPageContent[] = [
    {
      mainTitle: governance.abtus_gvrn_mainhead,
      article: [governance.abtus_gvrn_mainhead_paraone + " " + governance.abtus_gvrn_mainhead_paratwo],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headone,
          article: [
            governance.abtus_gvrn_headone_paraone +
            " " +
            governance.abtus_gvrn_headone_paratwo +
            " " +
            governance.abtus_gvrn_headone_parathree,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headtwo,
          article: [
            governance.abtus_gvrn_headtwo_paraone +
            " " +
            governance.abtus_gvrn_headtwo_paratwo +
            " " +
            governance.abtus_gvrn_headtwo_parathree,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headthree,
          article: [
            governance.abtus_gvrn_headthree_paraone +
            " " +
            governance.abtus_gvrn_headthree_paratwo +
            " " +
            governance.abtus_gvrn_headthree_parathree +
            " " +
            governance.abtus_gvrn_headthree_parafour +
            " " +
            governance.abtus_gvrn_headthree_parafive,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headfour,
          article: [
            governance.abtus_gvrn_headfour_paraone +
            " " +
            governance.abtus_gvrn_headfour_paratwo +
            " " +
            governance.abtus_gvrn_headfour_parathree +
            " " +
            governance.abtus_gvrn_headfour_parafour,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headfive,
          article: [governance.abtus_gvrn_headfive_paraone + " " + governance.abtus_gvrn_headfive_paratwo],
        },
      ],
    },
  ];
  const middleTextValue: IPageContent[] = [
    {
      inlineList: [
        {
          inlineSubtitle: governance.abtus_gvrn_headfive_hdone,
          article: [governance.abtus_gvrn_headfive_txtone],
        },
        {
          inlineSubtitle: governance.abtus_gvrn_headfive_hdtwo,
          article: [governance.abtus_gvrn_headfive_txttwo],
        },
        {
          inlineSubtitle: governance.abtus_gvrn_headfive_hdthree,
          article: [governance.abtus_gvrn_headfive_txtthree],
        },
        {
          inlineSubtitle: governance.abtus_gvrn_headfive_hdfour,
          article: [governance.abtus_gvrn_headfive_txtfour],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headsix,
          article: [governance.abtus_gvrn_headsix_paraone + " " + governance.abtus_gvrn_headsix_paratwo],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headseven,
          article: [
            governance.abtus_gvrn_headseven_paraone +
            " " +
            governance.abtus_gvrn_headseven_paratwo +
            " " +
            governance.abtus_gvrn_headseven_parathree +
            " " +
            governance.abtus_gvrn_headseven_parafour +
            " " +
            governance.abtus_gvrn_headseven_parafive,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headeight,
          article: [
            governance.abtus_gvrn_headeight_paraone +
            " " +
            governance.abtus_gvrn_headeight_paratwo +
            " " +
            governance.abtus_gvrn_headeight_parathree +
            " " +
            governance.abtus_gvrn_headeight_parafour +
            " " +
            governance.abtus_gvrn_headeight_parafive,
          ],
        },
      ],
    },
    {
      title: governance.abtus_gvrn_headnine,
      article: [
        governance.abtus_gvrn_headnine_paraone +
        " " +
        governance.abtus_gvrn_headnine_paratwo +
        " " +
        governance.abtus_gvrn_headnine_parathree,
      ],
    },
    {
      titledList: [{ upperSubtitle: governance.abtus_gvrn_headten, article: [governance.abtus_gvrn_headten_paraone] }],
    },
  ];
  const bottomTextValue: IPageContent[] = [
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headeleven,
          article: [governance.abtus_gvrn_headeleven_paraone],
        },
        {
          upperSubtitle: governance.abtus_gvrn_headtwelve,
          article: [governance.abtus_gvrn_headtwelve_paraone],
        },
        {
          upperSubtitle: governance.abtus_gvrn_headthirteen,
          article: [governance.abtus_gvrn_headthirteen_paraone],
        },
        {
          upperSubtitle: governance.abtus_gvrn_headfourteen,
          article: [governance.abtus_gvrn_headfourteen_paraone],
        },
        {
          upperSubtitle: governance.abtus_gvrn_headfifteen,
          article: [governance.abtus_gvrn_headfifteen_paraone],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headsixteen,
          article: [
            governance.abtus_gvrn_headsixteen_paraone +
            " " +
            governance.abtus_gvrn_headsixteen_paratwo +
            " " +
            governance.abtus_gvrn_headsixteen_parathree,
          ],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headseventeen,
          article: [governance.abtus_gvrn_headseventeen_paraone + " " + governance.abtus_gvrn_headseventeen_paratwo],
        },
      ],
    },
    {
      titledList: [
        {
          upperSubtitle: governance.abtus_gvrn_headeighteen,
          article: [governance.abtus_gvrn_headeighteen_paraone],
        },
      ],
      article: [
        governance.abtus_gvrn_headeighteen_paratwo +
        " " +
        governance.abtus_gvrn_headeighteen_parathree +
        " " +
        governance.abtus_gvrn_headeighteen_parafour,
        governance.abtus_gvrn_headeighteen_parafour_subtxtone,
        governance.abtus_gvrn_headeighteen_parafour_subtxttwo,
        governance.abtus_gvrn_headeighteen_parafour_subtxtthree,
        governance.abtus_gvrn_headeighteen_parafive +
        " " +
        governance.abtus_gvrn_headeighteen_parasix +
        " " +
        governance.abtus_gvrn_headeighteen_paraseven,
      ],
    },
  ];
  const schemeElements: RootlikeBlockSchemeProps = {
    root: governance.abtus_gvrn_headfive_chartheadone,
    li: [
      governance.abtus_gvrn_headfive_chartheadone_leafone,
      governance.abtus_gvrn_headfive_chartheadone_leaftwo,
      governance.abtus_gvrn_headfive_chartheadone_leafthree,
      governance.abtus_gvrn_headfive_chartheadone_leaffour,
    ],
  };
  return (
    <>
      <HelmetContainer metaData={governance.metaData} />
      <SlideSection
        bgImage={MissionBg}
        title={parse(governance.abtus_gvrn_mainhead_nxt + " <br> " + governance.abtus_gvrn_mainhead)}
        bgVariant={"gradient"}
        justify={"center"}
        titleAlign={"center"}
        titleWidth={"100%"}
        BgImageStyles={
          isMobile
            ? {
              backgroundRepeat: "no-repeat",
              backgroundPosition: "60%",
            }
            : {
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }
        }
      />
      <Section mainContent m={"9rem auto 19rem"}>
        <TextContent content={upperTextValue} />
        <RootlikeBlockScheme props={schemeElements} />
        <TextContent content={middleTextValue} />
        <Wrap sx={{ width: "100%", borderBottom: "1px solid #797979" }} />
        <Wrap sx={{ textAlign: "center", width: "100%", margin: "70px 0px" }}>
          <GovernanceHeading>{governance.abtus_gvrn_site_grp}</GovernanceHeading>
          <GovernanceText>{governance.abtus_gvrn_site_grp_title}</GovernanceText>
        </Wrap>
        <GovernanceMapImg src={map} />
        <Wrap
          sx={{ width: "100%", margin: "0px auto", display: "flex", flexDirection: "column", alignItems: "center" }}
        >
          <Wrap>
            <GovernanceTreeBlock bg={"#505050"}>
              <GovernanceTreeBlockName size>{governance.abtus_gvrn_bst}</GovernanceTreeBlockName>
            </GovernanceTreeBlock>
            <GovernanceDivider>
              <GovernanceDividerColor size={7}>
                <GovernanceDividerText>{governance.abtus_gvrn_bst_head_pct}</GovernanceDividerText>
              </GovernanceDividerColor>
            </GovernanceDivider>
            <GovernanceTreeBlock {...!isMobile && { size : 400 }}>
              <GovernanceTreeBlockName size>{governance.abtus_gvrn_bst_grp_name}</GovernanceTreeBlockName>
              <GovernanceTreeBlockPlace>{governance.abtus_gvrn_bst_grp_place}</GovernanceTreeBlockPlace>
            </GovernanceTreeBlock>
            <GovernanceDivider>
              <GovernanceDividerColor size={7}>
                <GovernanceDividerText>{governance.abtus_gvrn_bst_head_pct}</GovernanceDividerText>
              </GovernanceDividerColor>
            </GovernanceDivider>
          </Wrap>

          <Wrap
            sx={{
              width: "100%",
              height: "1px",
              border: "1px solid #252525",
              margin: "0px auto"
            }}
          />
          <Wrap sx={{ display: "flex", justifyContent: "space-between", columnGap: isMobile ? '1rem' : '20px', width: '100%' }}>
            <Wrap >
              {treeColumnOne.map((item) => {
                return (
                  <>
                    <GovernanceDivider>
                      <GovernanceDividerColor />
                    </GovernanceDivider>
                    <GovernanceTreeBlock>
                      <GovernanceTreeBlockName size={item.fontSize}>{item.name}</GovernanceTreeBlockName>
                      <GovernanceTreeBlockPlace>{item.place}</GovernanceTreeBlockPlace>
                    </GovernanceTreeBlock>
                  </>
                );
              })}
            </Wrap>
            <Wrap>
              {treeColumnTwo.map((item) => {
                return (
                  <>
                    <GovernanceDivider>
                      <GovernanceDividerColor />
                    </GovernanceDivider>
                    <GovernanceTreeBlock>
                      <GovernanceTreeBlockName size={item.fontSize}>{item.name}</GovernanceTreeBlockName>
                      <GovernanceTreeBlockPlace>{item.place}</GovernanceTreeBlockPlace>
                    </GovernanceTreeBlock>
                  </>
                );
              })}
            </Wrap>
            <Wrap>
              {treeColumnThree.map((item) => {
                return (
                  <>
                    <GovernanceDivider>
                      <GovernanceDividerColor />
                    </GovernanceDivider>
                    <GovernanceTreeBlock>
                      <GovernanceTreeBlockName size={item.fontSize}>{item.name}</GovernanceTreeBlockName>
                      <GovernanceTreeBlockPlace>{item.place}</GovernanceTreeBlockPlace>
                    </GovernanceTreeBlock>
                  </>
                );
              })}
            </Wrap>
            <Wrap>
              {treeColumnFour.map((item) => {
                return (
                  <>
                    <GovernanceDivider>
                      <GovernanceDividerColor />
                    </GovernanceDivider>
                    <GovernanceTreeBlock>
                      <GovernanceTreeBlockName size={item.fontSize}>{item.name}</GovernanceTreeBlockName>
                      <GovernanceTreeBlockPlace>{item.place}</GovernanceTreeBlockPlace>
                    </GovernanceTreeBlock>
                  </>
                );
              })}
            </Wrap>
            <Wrap>
              {treeColumnFive.map((item) => {
                return (
                  <>
                    <GovernanceDivider>
                      <GovernanceDividerColor />
                    </GovernanceDivider>
                    <GovernanceTreeBlock>
                      <GovernanceTreeBlockName size={item.fontSize}>{item.name}</GovernanceTreeBlockName>
                      <GovernanceTreeBlockPlace>{item.place}</GovernanceTreeBlockPlace>
                    </GovernanceTreeBlock>
                  </>
                );
              })}
            </Wrap>
          </Wrap>
        </Wrap>
        <Wrap sx={{ width: "100%", borderBottom: "1px solid #797979", marginTop: "100px" }} />
        <TextContent content={bottomTextValue} />
      </Section>
    </>
  );
};
