import styled from "styled-components";
import { media } from "../../utils/use-media-query";

export const CodeOFConductHeading = styled.h5`
  text-align: center;
  margin-top: 30px;
  font-size: 2rem;
  @media (${media.sm}) {
     {
      font-size: 1.5rem;
    }
  }
`;
export const CodeOFConductMessageDiv = styled.div`
  position: relative;
  border: 1px solid #54595d;
  border-radius: 15px;
  padding: 25px;
  margin-top: 50px;
`;
export const CodeOFConductMessageForCEO = styled.p`
  
  font-style: italic;
  color: #979797;
  font-size: 20px;
  @media (max-width: 992px) {
    font-size: 16px;
  }
`;
export const CodeOFConductComa = styled.img`
  position: absolute;
  width: 50px;
  height: 50px;
  top: -3rem;
  left: 2.5rem;
`;
export const CodeOFConductComaReverse = styled.img`
  position: absolute;
  width: 50px;
  height: 50px;
  right: 2.5rem;
  bottom: -3rem;
`;
export const CodeOFConductVisionMainDiv = styled.div<{ border: boolean }>`
  display: flex;
  align-items: center;
  padding: 50px 0px 50px 60px;
  border-bottom: ${(props) => (props.border ? "1px solid grey" : "")};
  column-gap: 80px;
  width: 100%;
  @media (${media.sm}) {
     {
      padding: 10px 0px 20px 0px;
      column-gap: 20px;
      
    }
  }
`;
export const CodeOFConductVisionImgDiv = styled.div`
  width: 130px;
  height: 130px;
  @media (${media.sm}) {
     {
      width: 120px;
    }
  }
`;
export const CodeOFConductVisionImg = styled.img`
  width: 130px;
  height: 130px;
  @media (${media.sm}) {
     {
      width: 100px;
    }
  }
  
`;
export const CodeOFConductVisionHeading = styled.h4`
  margin-bottom: 10px;
  font-size: 28px;
  @media (${media.sm}) {
     {
      font-size: 16px;
      font-weight: 400;
      /* letter-spacing: 0.39px; */
    }
  }
`;

export const CodeOFConductVisionText = styled.p`
  font-size: 20px;
  text-align: justify;
  @media (max-width: 992px) {
    font-size: 16px;
  }
  @media (${media.sm}) {
     {
      flex-direction: column;
      font-size: 14px;
      line-height: 16.34px;
    }
  };
`;
