import styled from "styled-components";
import { media } from "../../utils/use-media-query";

const SocialTitleRoot = styled.div`
  display: flex;
  width: 100%;
  @media (${media.sm}) {
    flex-direction: column-reverse;
    justify-content: end;
  }
`;

const SocialTitleDate = styled.span`
  font-size: 1.3rem;
  line-height: 1.6rem;
  margin-right: 2.8rem;
  @media (${media.sm}) {
     {
     text-align: right;
     align-self: flex-end;
     margin-right: 0rem; 

    }
  }
`;
const SocialTitleText = styled.h6`
  line-height: 1.8rem;
  @media (${media.sm}) {
     {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
      text-transform: uppercase;

    }
  }
`;

export const SocT = {
  Root: SocialTitleRoot,
  Date: SocialTitleDate,
  Text: SocialTitleText,
};
