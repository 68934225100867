import styled from "styled-components";
import { media } from "../../utils/use-media-query";

export const GovernanceHeading = styled.h3`
  font-size: 28px;
`;
export const GovernanceText = styled.p`
  font-size: 28px;
  font-weight: 400;
`;
export const GovernanceMapImg = styled.img`
  width: 80%;
  margin: 0px auto;
`;
export const GovernanceTreeBlock = styled.div<{ size?: number; bg?: string }>`
  padding: 15px 5px;
  background-color: ${(props) => (props.bg ? props.bg : "transparent")};
  border: ${(props) => (props.bg ? "" : "2px solid #252525")};
  border-radius: 8px;
  width: ${(props) => (props.size ? `${props.size}px` : "auto")};
  margin: 0px auto;
  color: white;
  font-size: 20px;
  text-align: center;
`;
export const GovernanceTreeBlockName = styled.p<{ size: boolean }>`
  font-size: ${(props) => (props.size ? "20px" : "14px")};
  color: white;
  @media (${media.sm}) {
    font-size: 10px;
    line-height: 1.75rem;
  }
`;
export const GovernanceTreeBlockPlace = styled.p`
  font-size: 16px;
  color: #8d8d8d;
  margin-top: 8px;
  @media (${media.sm}) {
    font-size: 8px;
    line-height: 1.25rem;
  }

`;
export const GovernanceDivider = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  margin: 0px auto;
  align-items: center;
`;
export const GovernanceDividerColor = styled.div<{ size?: number }>`
  height: ${(props) => (props.size ? props.size : "3")}rem;
  width: 1px;
  border: 1px solid #252525;
  display: flex;
  align-items: center;
`;
export const GovernanceDividerText = styled.p`
  margin-left: 15px;
  color: #505050;
  font-weight: bold;
  font-size: 18px;
  @media (${media.sm}) {
    font-size: 10px;
  }
`;
